import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import BreadCrumb from "../BreadCrumb";
import ToastComponent from "../Common/Toast";
import Sidebar from "../Sidebar";
import moment from "moment";
import { selectFilter } from "react-bootstrap-table2-filter";
import { Form } from "react-bootstrap";
import { saveAs } from "file-saver";
import { getEvents } from "../../api/events";
import BlockingLoader from "../Common/Loader";
import { getCoupons } from "../../api/coupons";
import Table from "../Common/Table";
import DashboardHeader from "../DashboardHeader";
import { headerStyle } from "../../utils/tableStyles";

const CouponDashboard = () => {
  const { slug } = useParams();
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [eventsLoading, setEventsLoading] = useState(false);
  const [eventsError, setEventsError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setEventsError("");
        setEventsLoading(true);
        const response = await getEvents();
        setEvents(response.data);
      } catch (error) {
        console.log("Error during fetching events", error);
        setEventsError(error.message);
      } finally {
        setEventsLoading(false);
      }
    };

    fetchData();
  }, []);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");

  const [coupons, setCoupons] = useState([]);
  const [searchCoupon, setSearchCoupon] = useState("");
  const [couponsLoading, setCouponsLoading] = useState(false);
  const [couponsError, setCouponsError] = useState("");

  const fetchCoupons = async () => {
    try {
      setCouponsError("");
      setCouponsLoading(true);
      const response = await getCoupons();
      setCoupons(
        response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } catch (error) {
      console.log("Error during fetching coupons", error);
      setCouponsError(error.message);
    } finally {
      setCouponsLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const handleToggleChange = async (couponId, isActive) => {
    try {
      setCoupons((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === couponId ? { ...coupon, isActive: isActive } : coupon
        )
      );
      await axios.put(`${baseUrl}coupons/editcoupon?couponId=${couponId}`, {
        isActive: isActive,
      });
      setShowToast(true);
      setToastMessage(
        `Coupon ${isActive ? "activated" : "deactivated"} successfully!`
      );
      setToastVariant("success");
    } catch (error) {
      console.error("Error updating coupon:", error);
      setShowToast(true);
      setToastMessage("Failed to update coupon. Please try again.");
      setToastVariant("danger");
    }
  };
  const handleDelete = async (couponId) => {
    console.log("Deleting coupon with ID:", couponId);
    try {
      await axios.delete(`${baseUrl}coupons/deletecoupon?couponId=${couponId}`);
      await fetchCoupons();
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };
  const formatExpiresAt = (expiresAt) => {
    return moment(expiresAt).format("YYYY-MM-DD HH:mm:ss");
  };
useEffect(()=>{
if(showToast){
  window.scrollTo({top:0, behavior: "smooth"});
}
}, [showToast])
  const tableHeaders = [
    {
      dataField: "couponCode",
      text: "Coupon Code",
      headerStyle: headerStyle,
    },
    {
      dataField: "expiresAt",
      text: "Expires At",
      formatter: (cell, row) => formatExpiresAt(cell),
      headerStyle: headerStyle,
    },
    {
      dataField: "maxRunners",
      text: "Max Runners",
      headerStyle: headerStyle,
    },
    {
      dataField: "remainingRunners",
      text: "Remaining Runners",
      headerStyle: headerStyle,
    },
    {
      dataField: "discountAmount",
      text: "Discount Amount",
      headerStyle: headerStyle,
    },
    {
      dataField: "discountPercentage",
      text: "Discount Percentage",
      headerStyle: headerStyle,
    },
    {
      dataField: "eventName",
      text: "Event",
      filter: selectFilter({
        options: events.reduce((acc, event) => {
          acc[event.eventName] = event.eventName;
          return acc;
        }, {}),
        placeholder: "Select Event",
        onFilter: (filterValue) => {
          setSelectedEvent(filterValue);
        },
        style: {
          width: "auto",
          marginLeft: "3px",
        },
        className: "form-select",
      }),
      headerStyle: headerStyle,
    },
    {
      dataField: "isActive",
      text: "Is Active",
      headerStyle: headerStyle,
    },

    {
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-row gap-2 align-items-center">
            <Link
              className="btn text-secondary border-0"
              to={`/create-coupon?slug=${slug}`}
              state={{ row }}
            >
              <i className="fa fa-edit"></i>
            </Link>
            {/* <button
              className="btn btn-danger"
              onClick={() => handleDelete(row.id)} 
            >
             <i className="fa fa-trash"></i>
            </button> */}

            <Form>
              <Form.Check
                type="switch"
                id={`custom-switch-${row.id}`}
                label=""
                checked={row.isActive}
                onChange={(e) => handleToggleChange(row.id, e.target.checked)}
              />
            </Form>
          </div>
        );
      },
      headerStyle: headerStyle,
    },
  ];

  const rowStyle = {
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    textAlign: "center",
  };

  const downloadCouponCodes = () => {
    if (selectedEvent) {
      const filteredCoupons = coupons.filter(
        (coupon) => coupon.eventName === selectedEvent
      );
      const couponCodes = filteredCoupons
        .map((coupon) => coupon.couponCode)
        .join("\n");
      const blob = new Blob([couponCodes], { type: "text/csv" });
      saveAs(blob, `coupon_codes_${selectedEvent}.csv`);
    } else {
      const couponCodes = coupons.map((coupon) => coupon.couponCode).join("\n");
      const blob = new Blob([couponCodes], { type: "text/csv" });
      saveAs(blob, "coupon_codes.csv");
    }
  };

  const handleSearch = () => {
    const filteredCoupons = coupons.filter((coupon) =>
      coupon.couponCode.toLowerCase().includes(searchCoupon.toLowerCase())
    );
    setCoupons(filteredCoupons);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearchCouponChange = (e) => {
    setSearchCoupon(e.target.value);
    if (e.target.value === "") {
      fetchCoupons();
    }
  };

  return (
    <>
      <style>
        {`
          .table-container {
            width: 100%;
            overflow-x: auto;
          }

          .sticky-header th {
            position: sticky;
            top: 0;
            background-color: #fff; 
            z-index: 1; 
          }
        `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />

      <div className="content">
        <BreadCrumb task={"Coupons"} />
        <div className="row justify-content-center mt-4">
          <div className="col-auto">
            <div className="d-flex flex-row gap-1">
              <input
                type="text"
                placeholder="Search by coupon code"
                value={searchCoupon}
                onChange={(e) => handleSearchCouponChange(e)}
                style={{ width: "250px" }}
                onKeyPress={handleKeyPress}
                className="form-control border"
              />
              <div className="input-group-append">
                <button
                  className="btn border"
                  type="button"
                  onClick={handleSearch}
                >
                  <i className="bi bi-search text-light-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-end gap-md-2 py-4">
          <Link
            className="btn border btn-primary mobile-width"
            to={`/usage-coupon/${slug}`}
          >
            Usage
          </Link>
          <Link
            className="btn border btn-primary mobile-width"
            to={`/create-coupon?slug=${slug}`}
          >
            Create Coupon
          </Link>
          <button
            className="btn border btn-primary mobile-width"
            onClick={downloadCouponCodes}
          >
            Coupons Download
          </button>
          <Link
            className="btn border btn-primary mobile-width"
            to={`/bulk-coupon?slug=${slug}`}
          >
            Bulk Coupon Create
          </Link>
        </div>
        {eventsLoading || couponsLoading ? (
          <BlockingLoader />
        ) : (
          <>
            <Table
              keyField="id"
              data={coupons}
              columns={tableHeaders}
              rowStyle={rowStyle}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setPerPage={setPerPage}
              perPage={perPage}
            />
            <ToastComponent
      showToast={showToast}
      toastHeader="Coupon Update"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
          </>
        )}
        {eventsError ? <p className="error-message">{eventsError}</p> : null}
        {couponsError ? <p className="error-message">{couponsError}</p> : null}
      </div>
    </>
  );
};

export default CouponDashboard;
