import httpClient from "./httpClient";

export const getSheets = () => {
  return httpClient({
    method: "GET",
    // TODO have to send the exact value not hardcode
    headers: {
      role: "super admin",
    },
    url: "/events/get-sheets",
  });
};
