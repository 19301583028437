import httpClient from "./httpClient";

export const getResults = (params) => {
  return httpClient({
    method: "GET",
    url: "/events/get-results",
    params,
  });
};

export const getStartListBySlug = (params) => {
  return httpClient({
    method: "GET",
    url: "/events/get-startlist",
    params,
  });
};
