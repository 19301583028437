import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SidebarEvent from "./SidebarEvent";
import DashboardHeader from "./DashboardHeader";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import ToastComponent from "./Common/Toast";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { getStartListBySlug } from "../api/results";
import { getEventBySlug } from "../api/events";

const UploadStartList = ({ slug, results }) => {
  console.log(slug, "slug");
  console.log(results, "live resuls");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  //const slug = new URLSearchParams(location.search).get("slug");
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(
        //   `${baseUrl}events/get-eventbyslug?slug=${slug}`
        // );
        const response = await getEventBySlug({
          slug: slug,
        });
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const [isOpen, setIsOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      file: "",
    },

    validationSchema: Yup.object().shape({
      file: Yup.string().required("File is required"),
    }),

    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("eventId", values.eventId);

      try {
        await axios.post(
          `${baseUrl}events/upload-startlist?event_slug=${slug}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setToastMessage(`Uploaded the start list XL sheet to s3 successfully!`);
        setShowToast(true);
        setToastVariant("success");
      } catch (error) {
        setShowToast(true);
        setToastMessage("Error during file upload");
        setToastVariant("danger");
      }
    },
  });
  useEffect(() => {
    const eventId = event ? event?.id : null;
    formik.setFieldValue("eventId", eventId);
  }, [event]);

  useEffect(() => {
    if (showToast) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showToast]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const [startlist, setStartlist] = useState([]);
  console.log(event?.category, "Category");
  useEffect(() => {
    const fetchResults = async () => {
      // const res = await axios.get(
      //   `${baseUrl}events/get-startlist?event_slug=${slug}`
      // );
      const res = await getStartListBySlug({
        event_slug: slug,
      });
      const startlistData = await res?.data;
      const enhancedStartlist = startlistData.map((racer) => {
        const resultRacer =
          results && results.find((result) => result?.Bib === racer?.Bib);
        if (resultRacer && resultRacer.ChipTime) {
          return {
            ...racer,
            ChipTime: resultRacer && resultRacer.ChipTime,
          };
        } else {
          return racer;
        }
      });

      setStartlist(enhancedStartlist);
    };
    fetchResults();
  }, [slug, results]);

  const columns = [
    {
      dataField: "Bib",
      text: "Bib",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Name",
      text: "Name",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "300px",
      },
    },
    {
      dataField: "Category",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
        width: "300px",
      },
      text: <div className="text-center">Category</div>,
      filter: selectFilter({
        options:
          startlist
            ?.map((item) => item?.Category)
            ?.reduce((acc, item) => {
              acc[item] = item;
              return acc;
            }, {}) || {},
        placeholder: "Select Category",
        style: {
          width: "auto",
          margin: "auto",
        },
        className: "form-select",
      }),
    },
    {
      dataField: "Age",
      text: "Age",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Gender",
      text: "Gender",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "ChipTime",
      text: "Chip Time",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "SMSTriggered",
      text: "SMS Triggered",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
  ];
  const rowStyle = {
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    textAlign: "center",
  };
  console.log(startlist, "startlist");
  return (
    <>
      <style>{`
    body {
      overflow-x: hidden;
    }
    .sticky-header th {
      position: sticky;
      vertical-align:middle;
       padding-top:5px;
       padding-bottom:5px;
       cursor:pointer;
       white-space:nowrap;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }

    @media (max-width: 767px) {
      .mobile-width {
        width: 50%;
        margin: 0 auto;
      }
    }
    .react-bootstrap-table-pagination {
      position: sticky;
     left: 0%;
      transform: translateX(0%);
      z-index: 1000;
    }

  `}</style>

      <div className="content">
        <div className="p-3">
          <div className="row y-gap-20 justify-center items-center">
            <form
              className="col-xl-7 col-lg-8 mt-30 mx-auto absolute"
              id="reg"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <div className="row justify-content-center">
                <div className="col-md-6 mt-4">
                  <label className="text-16 text-dark-1 fw-bold mb-2">
                    Upload Start List
                  </label>
                  <input
                    type="file"
                    className="form-control text-dark-1"
                    id="file"
                    name="file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "file",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.file && formik.errors.file ? (
                    <div className="text-danger">{formik.errors.file}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4 text-center">
                <div className="col-12">
                  <button className="btn btn-primary" type="submit">
                    Upload
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="mt-3">
            {startlist && startlist?.length > 0 ? (
              <div
                style={{ maxHeight: "450px", overflowY: "auto" }}
                className="table-responsive"
              >
                <BootstrapTable
                  keyField="id"
                  data={startlist}
                  columns={columns}
                  filter={filterFactory()}
                  striped
                  hover
                  condensed
                  rowStyle={rowStyle}
                  headerClasses="sticky-header"
                  pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 20,
                    totalSize: startlist.length,
                    onPageChange: (page, sizePerPage) => {
                      setCurrentPage(page);
                    },
                    onSizePerPageChange: (page, sizePerPage) => {
                      setCurrentPage(1);
                      setPerPage(sizePerPage);
                    },
                  })}
                />
              </div>
            ) : (
              <p
                className="text-center mt-4 text-danger"
                style={{ fontWeight: "bold" }}
              >
                No items to display, please upload start list
              </p>
            )}
          </div>
        </div>
        <ToastComponent
      showToast={showToast}
      toastHeader="Upload Startlist"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  );
};

export default UploadStartList;
