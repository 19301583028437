import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import BreadCrumb from "./BreadCrumb";
import ExportCsv from "./ExportCsv";
import DistributeQrs from "./DistributeQrs";
import RunnerDetailsModal from "./RunnerDetailsModal";
import "bootstrap-icons/font/bootstrap-icons.css";
import SelectColumnsToDisplay from "./SelectColumnsToDisplay";
import cellEditFactory from "react-bootstrap-table2-editor";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import BlockingLoader from "./Common/Loader";

const ResultTable = ({
  slug,
  eventId,
  eventName,
  isOpen,
  setIsOpen,
  status,
  setStatus,
  runners,
  noRecords,
  setRunners,
  setTotalRunners,
  setNoRecords,
  isDistributedQr = false,
  isWhatsUp = false,
  isEmail = false,
  isOrderStatus = false,
  isExportCsv = false,
  loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRunner, setSelectedRunner] = useState(null);
  const [isExport, setIsExport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortIcon, setSortIcon] = useState({});
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedAgeRange, setSelectedAgeRange] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [event, setEvent] = useState(null);

  const fetchData = async () => {
    const response = await axios.get(
      `${baseUrl}events/get-eventbyslug?slug=${slug}&orderStatus='COMPLETED'`
    );
    setEvent(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [slug]);

  const categories = event?.category || [];

  const handleOrderStatusChange = async (e) => {
    try {
      setStatus(e.target.value);
      const response = await axios.get(
        `${baseUrl}users/eventIdAndOrderStatus?eventId=${eventId}&status=${e.target.value}`
      );
      if (response?.data?.length) {
        response?.data?.forEach((obj) => {
          const dateOfBirth = new Date(obj.dateOfBirth);
          obj.dateOfBirth = dateOfBirth.toISOString().split("T")[0];
        });
        setRunners(response.data.sort((a, b) => b.createdBy - a.createdBy));
        setTotalRunners(response.data.length);
      }
      setNoRecords(response.data.length === 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleExport = () => {
    setIsExport(!isExport);
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, row]);
      } else {
        setSelectedRows(
          selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
        );
      }
    },
  };
  const handleMoreClick = (runner) => {
    setSelectedRunner(runner);
  };
  const handleSort = (field) => {
    const isAlt = sortIcon[field] === "bi-sort-alpha-down-alt";
    setSortIcon({
      ...sortIcon,
      [field]: isAlt ? "bi-sort-alpha-down" : "bi-sort-alpha-down-alt",
    });
  };
  const excludedColumns = [
    "street",
    "city",
    "eventId",
    "updatedAt",
    "id",
    "paymentId",
    "country",
    "state",
    "pincode",
    "Order",
    "orderId",
    "groupEventId",
    "acceptedTerms",
  ];
  const tableHeaders =
    runners?.length > 0
      ? Object.keys(runners[0]).filter(
          (header) => !excludedColumns.includes(header)
        )
      : [];

  const displayNames = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    mobileNumber: "Mobile Number",
    gender: "Gender",
    dateOfBirth: "Date Of Birth",
    tShirtSize: "T-Shirt Size",
    nameOfTheBib: "Name On The Bib",
    categoryName: "Category",
    createdAt: "Time Of Registration",
    bloodGroup: "Blood Group",
    contactName: "Contact Name",
    contactNumber: "Contact Number",
    medicalIssue: "Medical Issue",
    runnerClub: "Runner Club",
    distance: "Distance",
    disorders: "Disorders",
    chronicIssues: "Chronic Issues",
    bibNumber: "Bib Number",
    chipCode: "Chip Code",
    runnerId: "Runner Id",
    isDistributed: "Is Distributed",
    distributedBy: "Distributed By",
    isIdVerified: "Is Id Verified",
    qrImageUrl: "Qr Image Url",
    isQRCodeSend: "QR Code Send",
    address: "Address",
    age: "Age",
    isResultSMSSend: "Is Result SMS Send",
  };
  const [columnVisibility, setColumnVisibility] = useState(false);
  const toggleColumn = (columnName) => {
    setColumnVisibility((prevVisibility) => {
      const currentVisibility = prevVisibility[columnName];
      const updatedVisibility = !currentVisibility;
      console.log(updatedVisibility, "update visibility");
      return {
        ...prevVisibility,
        [columnName]: updatedVisibility,
      };
    });
  };
  const toggleButtons = tableHeaders.map((header, index) => (
    <button
      className={`btn ${
        !columnVisibility[header] ? "btn-primary" : "bg-dark text-white"
      } rounded-0 border`}
      key={index}
      onClick={() => toggleColumn(header)}
      style={{ whiteSpace: "nowrap" }}
    >
      {displayNames[header]}
    </button>
  ));
  const [isToggleButtons, setIsToggleButtons] = useState(false);
  const [showModalTable, setShowModalTable] = useState(false);
  const [selectedColumnsTable, setSelectedColumnsTable] = useState([
    "firstName",
    "lastName",
    "categoryName",
    "createdAt",
    "tShirtSize",
  ]);

  const handleDragStart = (e, dataIndex) => {
    e.dataTransfer.setData("text/plain", dataIndex);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const draggedColumnIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const newOrder = [...selectedColumnsTable];
    newOrder.splice(newIndex, 0, newOrder.splice(draggedColumnIndex, 1)[0]);
    setSelectedColumnsTable(newOrder);
  };

  const handleSelectTable = () => {
    setShowModalTable(false);
    setSelectedColumnsTable(selectedColumnsTable);
  };
  const handleSelectColumns = () => {
    setShowModalTable(true);
  };
  const ageRanges = [
    { label: "0-10", minAge: 0, maxAge: 10 },
    { label: "11-20", minAge: 11, maxAge: 20 },
    { label: "21-30", minAge: 21, maxAge: 30 },
    { label: "31-40", minAge: 31, maxAge: 40 },
    { label: "41-50", minAge: 41, maxAge: 50 },
  ];
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    let filteredData = runners;

    if (selectedGender) {
      filteredData = filteredData.filter(
        (user) => user.gender === selectedGender
      );
    }

    if (selectedSize) {
      filteredData = filteredData.filter(
        (user) => user.tShirtSize === selectedSize
      );
    }

    if (selectedCategoryName) {
      filteredData = filteredData.filter(
        (user) => user.categoryName === selectedCategoryName
      );
    }
    if (selectedAgeRange) {
      const selectedRange = ageRanges.find(
        (range) => range.label === selectedAgeRange
      );
      if (selectedRange) {
        filteredData = filteredData.filter((user) => {
          return (
            parseInt(user?.age) >= selectedRange.minAge &&
            parseInt(user?.age) <= selectedRange.maxAge
          );
        });
      }
    }

    setFilteredUsers(filteredData);
  }, [
    selectedGender,
    selectedSize,
    selectedCategoryName,
    selectedAgeRange,
    runners,
  ]);

  const [editedFields, setEditedFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    contactNumber: "",
    contactName: "",
    tShirtSize: "",
    nameOfTheBib: "",
    address: "",
  });

  const handleCellEditing = async (newValue, row, column) => {
    const fieldName = column.dataField;
    setEditedFields((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));

    try {
      const updatedUser = { ...row, [column.dataField]: newValue };
      const response = await axios.put(
        `${baseUrl}users/edit-user?userId=${row.id}`,
        updatedUser
      );
      console.log("User's information updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating user's information:", error);
    }
  };
  const columns =
    selectedColumnsTable && selectedColumnsTable?.length > 0
      ? selectedColumnsTable.map((header, index) => {
          const isEditable =
            header === "firstName" ||
            header === "lastName" ||
            header === "email" ||
            header === "mobileNumber" ||
            header === "contactNumber" ||
            header === "contactName" ||
            header === "tShirtSize" ||
            header === "nameOfTheBib" ||
            header === "address" ||
            header === "dateOfBirth" ||
            header === "bloodGroup" ||
            header === "medicalIssue" ||
            header === "runnerClub" ||
            header === "gender";
          const editIcon = isEditable ? (
            <i className="far fa-edit" style={{ marginLeft: "5px" }}></i>
          ) : null;
          let customHeader = header;
          switch (header) {
            case "firstName":
              customHeader = "First Name";
              break;
            case "lastName":
              customHeader = "Last Name";
              break;
            case "email":
              customHeader = "Email";
              break;
            case "mobileNumber":
              customHeader = "Mobile Number";
              break;
            case "gender":
              customHeader = "Gender";
              break;
            case "dateOfBirth":
              customHeader = "Date Of Birth";
              break;
            case "tShirtSize":
              customHeader = "T-Shirt Size";
              break;
            case "nameOfTheBib":
              customHeader = "Name On The Bib";
              break;
            case "categoryName":
              customHeader = "Category";
              break;
            case "createdAt":
              customHeader = "Time Of Registration";
              break;
            case "bloodGroup":
              customHeader = "Blood Group";
              break;
            case "contactName":
              customHeader = "Contact Name";
              break;
            case "contactNumber":
              customHeader = "Contact Number";
              break;
            case "medicalIssue":
              customHeader = "Medical Issue";
              break;
            case "runnerClub":
              customHeader = "Runner Club";
              break;
            case "distance":
              customHeader = "Distance";
              break;
            case "bibNumber":
              customHeader = "Bib Number";
              break;
            case "chipCode":
              customHeader = "Chip Code";
              break;
            case "runnerId":
              customHeader = "Runner Id";
              break;
            case "isDistributed":
              customHeader = "Is Distributed";
              break;
            case "distributedBy":
              customHeader = "Distributed By";
              break;
            case "isIdVerified":
              customHeader = "Is Id Verified";
              break;
            case "qrImageUrl":
              customHeader = "Qr Image Url";
              break;
            case "isQRCodeSend":
              customHeader = "Is QR Code Send";
              break;
            case "address":
              customHeader = "Address";
              break;
            case "isResultSMSSend":
              customHeader = "Is Result SMS Send";
              break;
            case "age":
              customHeader = (
                <div>
                  <p className="text-center">Age</p>
                  <select
                    className="form-select"
                    style={{ width: "100px", marginTop: "3px" }}
                    value={selectedAgeRange}
                    onChange={(e) => setSelectedAgeRange(e.target.value)}
                  >
                    <option value="">Select Age Range</option>
                    {ageRanges.map((range) => (
                      <option key={range.label} value={range.label}>
                        {range.label}
                      </option>
                    ))}
                  </select>
                </div>
              );
              break;
            default:
          }

          return {
            dataField: header,

            text: (
              <div
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, index)}
              >
                <p
                  className="text-center m-0"
                  onClick={() => handleSort(header)}
                >
                  {" "}
                  {customHeader}{" "}
                  {header !== "categoryName" &&
                    header !== "age" &&
                    header !== "tShirtSize" && (
                      <>
                        <i
                          className={`bi ${
                            sortIcon[header] || "bi-sort-alpha-down"
                          }`}
                        ></i>{" "}
                        {editIcon}
                      </>
                    )}
                </p>
              </div>
            ),
            sort:
              header !== "categoryName" &&
              header !== "age" &&
              header !== "tShirtSize",
            formatter: (cell, row) => {
              if (header === "dateOfBirth") {
                return moment(row.dateOfBirth).format("DD/MM/YYYY");
              } else if (header === "createdAt") {
                return moment(row.createdAt).format("hh:mm DD/MM/YYYY");
              } else {
                return cell;
              }
            },
            filter:
              header === "gender" ||
              header === "tShirtSize" ||
              header === "categoryName"
                ? selectFilter({
                    options:
                      (header === "gender"
                        ? ["Male", "Female"]
                        : header === "tShirtSize"
                        ? [
                            "2-4 Yrs	24 inches",
                            "4-5 Yrs 26 inches",
                            "5-7 Yrs 28 inches",
                            "7-8 Yrs 30 inches",
                            "8-10 Yrs 32 inches",
                            "XS",
                            "S",
                            "M",
                            "L",
                            "XL",
                            "XXL",
                            "3XL",
                          ]
                        : categories?.map((category) => category.name)
                      )?.reduce((acc, item) => {
                        acc[item] = item;
                        return acc;
                      }, {}) || {},
                    placeholder: `Select ${customHeader}`,
                    onFilter: (filterValue) => {
                      if (header === "gender") {
                        setSelectedGender(filterValue);
                      } else if (header === "tShirtSize") {
                        setSelectedSize(filterValue);
                      } else {
                        setSelectedCategoryName(filterValue);
                      }
                    },
                    style: {
                      width: "100px",
                      marginTop: "3px",
                    },
                    className: "form-select",
                  })
                : header === "mobileNumber"
                ? textFilter({
                    placeholder: "Enter mobile number",
                    onFilter: (filterValue) => {
                      setMobileNumber(filterValue);
                    },
                    style: {
                      border: "1px solid lightgray",
                      width: "100px",
                      marginTop: "3px",
                    },
                  })
                : undefined,
            headerStyle: {
              verticalAlign: "middle",
              whiteSpace: "nowrap",
              paddingTop: "0.5em",
              paddingBottom: "0.5em",
              cursor: "pointer",
            },
            editable:
              header === "firstName" ||
              header === "lastName" ||
              header === "email" ||
              header === "mobileNumber" ||
              header === "contactNumber" ||
              header === "contactName" ||
              header === "tShirtSize" ||
              header === "nameOfTheBib" ||
              header === "address"
                ? true
                : false,
          };
        })
      : [];
  const rowStyle = {
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    textAlign: "center",
    cursor: "pointer",
  };

  const actionsColumn = {
    dataField: "more",
    text: "More",
    formatter: (cell, row) => (
      <button
        className="btn btn-outline-primary mb-3 mobile-width"
        onClick={() => handleMoreClick(row)}
      >
        More
      </button>
    ),
    headerStyle: {
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      paddingTop: "0.2em",
      paddingBottom: "0.2em",
      cursor: "pointer",
    },
  };
  const columnsWithActions = [...columns, actionsColumn];

  const resendNotifications = async () => {
    const selectedUserIds = selectedRows.map((runner) => runner.id);
    const response = await axios.post(`${baseUrl}users/resend-notifications`, {
      selectedUserIds,
    });
    console.log(response, "response");
  };
  const navigate = useNavigate();
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      navigate(`/register/${slug}`, { state: { row } });
    },
  };
  return (
    <>
      <style>{`
    body {
      overflow-x: hidden;
    }
    .sticky-header th {
      position: sticky;
      vertical-align:middle;
       padding-top:5px;
       padding-bottom:5px;
       cursor:pointer;
       white-space:nowrap;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }
 .react-bootstrap-table table tr td {
padding:0em;
}
    @media (max-width: 767px) {
      .mobile-width {
        width: 50%;
        margin: 0 auto;
      }
    }
    .react-bootstrap-table-pagination {
      position: sticky;
     left: 0%;
      transform: translateX(0%);
      z-index: 1000;
    }
    .react-bootstrap-table th i.bi-sort-alpha-down,
.react-bootstrap-table th i.bi-sort-alpha-down-alt {
  font-size: 1em;
}
  `}</style>

      <div>
        <BreadCrumb event={{ eventName }} slug={slug} task={"CRM"} />
        {loading ? (
          <BlockingLoader />
        ) : (
          <div className="dashboard">
            <div className="dashboard__main">
              <div className="dashboard__content bg-light-2">
                <div className="chart_responsive">
                  <div className="rounded-4 bg-white shadow-3">
                    <div className="container-fluid my-4">
                      <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">
                        <div
                          style={{ textAlign: "right", marginBottom: "10px" }}
                        >
                          <Link
                            className="btn btn-sm btn-outline-primary mobile-width"
                            to={`/register/${slug}`}
                          >
                            REGISTER
                          </Link>
                        </div>
                        {noRecords ? (
                          <>
                            {isOrderStatus && (
                              <div className="d-flex flex-column flex-md-row justify-content-end gap-2">
                                <select
                                  className="form-select-sm mb-3 border border-primary text-primary"
                                  value={status}
                                  onChange={handleOrderStatusChange}
                                >
                                  <option>COMPLETED</option>
                                  <option>CREATED</option>
                                  <option>FAILED</option>
                                </select>
                              </div>
                            )}
                            <div className="text-center pt-4 pb-4 font-weight-bold text-secondary">
                              Group Members Not Added
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex flex-column flex-md-row justify-content-end gap-2">
                              {isOrderStatus && (
                                <select
                                  className="form-select-sm mb-3 border border-primary text-primary mobile-width"
                                  value={status}
                                  onChange={handleOrderStatusChange}
                                >
                                  <option>COMPLETED</option>
                                  <option>CREATED</option>
                                  <option>FAILED</option>
                                </select>
                              )}
                              {isDistributedQr && (
                                <button
                                  className="btn btn-outline-primary mb-3 mobile-width"
                                  onClick={() => setShowModal(true)}
                                >
                                  Generate QRs
                                </button>
                              )}
                              {isExportCsv && (
                                <button
                                  className="btn btn-outline-primary mb-3 mobile-width"
                                  onClick={handleExport}
                                >
                                  Export CSV
                                </button>
                              )}
                              {isWhatsUp && (
                                <button className="btn btn-outline-primary mb-3 mobile-width">
                                  Send WhatsApp
                                </button>
                              )}
                              {isEmail && (
                                <button className="btn btn-outline-primary mb-3 mobile-width">
                                  Send Email
                                </button>
                              )}
                              <button
                                className="btn btn-outline-primary mb-3 mobile-width"
                                onClick={handleSelectColumns}
                              >
                                Enable Columns
                              </button>

                              <button
                                className="btn btn-outline-primary mb-3 mobile-width"
                                onClick={() =>
                                  setIsToggleButtons(!isToggleButtons)
                                }
                              >
                                Toggle Buttons
                              </button>
                              <button
                                className="btn btn-outline-primary mb-3 mobile-width"
                                onClick={resendNotifications}
                              >
                                Resend Notifications
                              </button>
                            </div>
                            <SelectColumnsToDisplay
                              show={showModalTable}
                              columns={tableHeaders}
                              selectedColumns={selectedColumnsTable}
                              setSelectedColumns={setSelectedColumnsTable}
                              onContinue={handleSelectTable}
                              onClose={() => setShowModalTable(false)}
                            />
                            {isToggleButtons && (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  top: "0",
                                  marginBottom: "10px",
                                  zIndex: "2",
                                  position: "sticky",
                                  overflowX: "auto",
                                }}
                              >
                                {toggleButtons}
                              </div>
                            )}
                            <div
                              style={{ maxHeight: "450px", overflowY: "auto" }}
                              className="table-responsive"
                            >
                              <BootstrapTable
                                keyField="id"
                                selectRow={selectRow}
                                data={filteredUsers}
                                columns={columnsWithActions.filter(
                                  (column) =>
                                    !columnVisibility[column.dataField]
                                )}
                                defaultSorted={[
                                  { dataField: "createdAt", order: "desc" },
                                ]}
                                cellEdit={cellEditFactory({
                                  mode: "click",
                                  afterSaveCell: (
                                    oldValue,
                                    newValue,
                                    row,
                                    column
                                  ) => handleCellEditing(newValue, row, column),
                                })}
                                striped
                                hover
                                search
                                condensed
                                rowEvents={rowEvents}
                                rowStyle={rowStyle}
                                filter={filterFactory()}
                                headerClasses="sticky-header"
                                pagination={paginationFactory({
                                  sizePerPage: 20,
                                })}
                              />
                              {selectedRunner && (
                                <RunnerDetailsModal
                                  runner={selectedRunner}
                                  onClose={() => setSelectedRunner(null)}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isExport && (
        <ExportCsv
          runners={runners}
          isOpen={isExport}
          setIsOpen={setIsExport}
          tableHeaders={tableHeaders}
        />
      )}
      {showModal && (
        <DistributeQrs
          runners={runners}
          showModal={showModal}
          setShowModal={setShowModal}
          eventName={eventName}
          categories={categories}
          slug={slug}
          eventId={eventId}
        />
      )}
    </>
  );
};

export default ResultTable;
