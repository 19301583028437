import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DashboardHeader from "../DashboardHeader";
import axios from "axios";
import { baseUrl } from "../../apiConfig";

import { useFormik } from "formik";
import * as Yup from "yup";
import ToastComponent from "../Common/Toast";
import Sidebar from "../Sidebar";

const CreateRunnerClub = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const location = useLocation();
  //const slug = new URLSearchParams(location.search).get("slug");
  const { state } = location;
  const row = state && state?.row;
  console.log(row, "item");
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  console.log(events, "events");
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Runner Club Name is required"),
    }),

    onSubmit: async (values) => {
      try {
        if (row) {
          await axios.put(
            `${baseUrl}users/runnerclub-edit?runnerClubId=${row?.id}`,
            values
          );
          setToastMessage(`Runner Club Name updated successfully!`);
        } else {
          await axios.post(`${baseUrl}users/runnerclub-create`, values);
          setToastMessage(`Runner Club Name created successfully!`);
        }
        setShowToast(true);
        setToastVariant("success");
        setTimeout(() => {
          navigate(`/runnerclub-list`);
        }, 1000);
      } catch (error) {
        console.error("Error creating runner club:", error);
        setShowToast(true);
        setToastMessage(
          row
            ? "Failed to update runner club"
            : "Failed to create runner club. Please try again."
        );
        setToastVariant("danger");
      }
    },
  });

  useEffect(() => {
    if (row) {
      formik.setValues({
        ...formik.values,
        name: row.name || "",
      });
    }
  }, [row]);

  useEffect(() => {
    if (showToast) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showToast]);

  return (
    <>
      <style>
        {`
      .form-input {
        //position: relative;
      
        //display: flex;
      
        label {
          //position: absolute;
          top: 0;
          pointer-events: none;
          font-size: 14px;
        }
      
        textarea,
        input {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
        select {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
      
      input:not(:placeholder-shown) {
        padding-top: 0px;
      }
      textarea:not(:placeholder-shown) {
        padding-top: 5px;
      }
        textarea:focus ~ label,
        textarea:valid ~ label,
        input:focus ~ label,
        input:valid ~ label {
          transform: translateY(-10px);
        }
      }

      `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} />

      <div className="content">
        <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
            <form
              className="col-xl-7 col-lg-8 mt-30 mx-auto"
              id="reg"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <div className="row x-gap-40 y-gap-20 justify-content-center">
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Runner Club Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>

              <div className="row mt-4 text-center">
                <div className="col-12">
                  <button className="btn btn-primary" type="submit">
                    {!row ? "Runner Club Create" : "Runner Club Update"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastComponent
      showToast={showToast}
      toastHeader={row ? "Runner Club Update" : "Runner Club Create"}
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  );
};

export default CreateRunnerClub;
