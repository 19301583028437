import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl } from "../apiConfig";

const DistributeQrs = ({ runners, showModal, setShowModal, eventName, categories,slug,eventId }) => {
  const handleModalClose = () => setShowModal(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [generateAllSelected, setGenerateAllSelected] = useState(true);
  const [generateSelectedSelected, setGenerateSelectedSelected] = useState(false);
  const runnersWithQRCodeSend = runners.filter(runner => {
    return runner.isQRCodeSend !== true;
  });
  const handleGenerateAllChange = () => {
    setGenerateAllSelected(true);
    setGenerateSelectedSelected(false);
  };

  const handleGenerateSelectedChange = () => {
    setGenerateAllSelected(false);
    setGenerateSelectedSelected(true);
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, row]);
      } else {
        setSelectedRows(selectedRows.filter((selectedRow) => selectedRow.id !== row.id));
      }
    },
  };
  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      filter: textFilter({
        placeholder: "Search Last Name",
        filterValue: null,
      }),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      filter: textFilter({ placeholder: "Search Email", filterValue: null }),
    },
    {
      dataField: "mobileNumber",
      text: "MobileNumber",
      sort: true,
      filter: textFilter({ placeholder: "Search Mobile number" }),
    },
    { dataField: 'categoryName', text: 'Category', sort: true, filter: selectFilter({ options: categories, placeholder: 'Select Category' }) },
    {
      dataField: "bibNumber",
      text: "Bib Number",
      sort: true,
      filter: textFilter({ placeholder: "Search bibNumber" }),
    },
  ];

  const handleDistribute = async () => {
    try {
      const response = await axios.post(`${baseUrl}events/distributeQrs`, {
        data: {
          slug:slug,
          eventId:eventId,
          eventName: eventName,
          selectedRows: generateSelectedSelected ?selectedRows :runnersWithQRCodeSend,
          generateQr: generateSelectedSelected ? 'selected' : 'all'
        }
      });
      if (response?.status === 200) {
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error distributing QR codes:", error);
    }
  };

  return (
    <div>
    <Modal
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Generate QR codes</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto" }}>
        <div style={{ maxHeight: "400px" }}>
        <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <input
          type="radio"
          id="generateAll"
          name="qrGeneration"
          value="all"
          checked={generateAllSelected}
          onChange={handleGenerateAllChange}
        />
        <label
          htmlFor="generateAll"
          style={{ marginLeft: "5px", marginRight: "15px" }}
        >
          Send QR for pending users
        </label>
        <input
          type="radio"
          id="generateSelected"
          name="qrGeneration"
          value="selected"
          checked={generateSelectedSelected}
          onChange={handleGenerateSelectedChange}
        />
        <label htmlFor="generateSelected" style={{ marginLeft: "5px" }}>
          Send QR for selected users
        </label>
      </div>
    
    {generateSelectedSelected &&
      <BootstrapTable
        selectRow={selectRow}
        keyField="id"
        data={runners}
        columns={columns}
        striped
        hover
        condensed
        filter={filterFactory()}
        headerClasses="sticky-header"
        pagination={paginationFactory()}
      />
    }   
    {!generateSelectedSelected &&
      <BootstrapTable
        keyField="id"
        data={runnersWithQRCodeSend}
        columns={columns}
        striped
        hover
        condensed
        filter={filterFactory()}
        headerClasses="sticky-header"
        pagination={paginationFactory()}
      />
    }    
   
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleDistribute}>Distribute</Button>
      </Modal.Footer>
    </Modal>
  </div>  );
};

export default DistributeQrs;
