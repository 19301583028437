
import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { baseUrl } from "../apiConfig";

const BibHistory = ({ eventId, firstTab, setFirstTab }) => {

  const [events, setEvents] = useState();
  const [noRecords, setNoRecords] = useState(true);
  const fetchData = async () => {
    const response = await axios.get(`${baseUrl}events/history?eventId=${eventId}`);
    if (response.status === 200) {
      setEvents(response?.data?.data)
      if ((response?.data?.data)?.length) {
        setFirstTab(false)
        setNoRecords(false)
      }
    }
  };

  useEffect(() => {
    if (firstTab) fetchData();
  }, [eventId, firstTab]);


  const tableHeaders = ['name', 'phoneNumber']
  const rowStyle = (row, rowIndex) => {
    return {
      verticalAlign: 'middle',
      lineHeight: "0.1em",
      whiteSpace: "nowrap"
    };
  };
  
  const columns = [
    {
      dataField: 'categoryName',
      text: 'Category Name',
      sort: true,
      style: { height: '50px' },
      headerStyle: { height: '50px' }

    },
    {
      dataField: 'bibStart',
      text: 'Bib starting Number',
      sort: true,
    },
    {
      dataField: 'bibEnd',
      text: 'Bib Ending Number',
      sort: true,
    },
    {
      dataField: 'chipCodeStart',
      text: 'ChipCode Start',
      sort: true,
    },
    {
      dataField: 'chipCodeEnd',
      text: 'chipCodeEnd',
      sort: true,
    },
  ];

  return (
    <>
      <style>
        {`
      body {
        overflow-x: hidden;
      }
      .sticky-header th {
        position: sticky;
        top: 0;
        background-color: #fff; 
        z-index: 1; 
      }
      @media (max-width: 767px) {
        .mobile-width {
          width: 50%;
          margin: 0 auto;
        }
      }
    `}
      </style>

      <div className="custom-min-height justify-content-center">
        <div className="">
          {noRecords ? <div className="text-center pt-45 font-weight-bold text-secondary">Bibs not generated</div> :
            <div className="chart_responsive">
              <div className="rounded-4 bg-white shadow-3">
                <div className="container-fluid my-4">
                  <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar custom-min-height">
                    {tableHeaders.length > 0 && (
                      <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        <BootstrapTable
                          keyField="id"
                          data={events}
                          columns={columns}
                          striped
                          hover
                          condensed
                          filter={filterFactory()}
                          rowStyle={rowStyle}
                          headerClasses="sticky-header"
                          pagination={paginationFactory()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

    </>
  );
};

export default BibHistory;
