import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SidebarEvent from "./SidebarEvent";
import DashboardHeader from "./DashboardHeader";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BreadCrumb from "./BreadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToastComponent from "./Common/Toast";
import { readExcelData } from "../utils/functions";
import UploadModal from "./Results/uploadModal/UploadModal";
import BlockingLoader from "./Common/Loader";

const UploadResultsTab = ({ slug }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const location = useLocation();
  const [event, setEvent] = useState(null);
  const [error,setError] = useState()
  const [inputValues, setInputValues] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [excelData, setExcelData] = useState();
  const [uploadLoader, setUploadLoader] = useState(false);
  const [uploadError, setUploadError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug, inputValues]);

  const [isOpen, setIsOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      file: "",
      race: "",
    },

    validationSchema: Yup.object().shape({
      file: Yup.string().required("File is required"),
      race: Yup.string().required("File is required"),
    }),

    onSubmit: async (values) => {
      console.log(values);
      await setInputValues({
        file: values.file,
        race: values.race,
        eventId: values.eventId,
      });
      await handleModalProceed(values);
      //setOpenModal(true);
    },
  });

  const handleModalProceed = async (values) => {
    // if (!data.eventCategory) {
    //   setUploadError("Please select event category");
    //   return;
    // }
    setUploadLoader(true);
    let formData = new FormData();
    formData.append("file", values.file);
    formData.append("eventId", values.eventId);
    formData.append("race", values.race);
    // formData.append("eventType", data.eventType);
    //formData.append("eventCategory", data.eventCategory);

    try {
      await axios.post(
        `${baseUrl}events/uploadxl?event_slug=${slug}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setToastMessage(`Uploaded the results XL sheet to s3 successfully!`);
      setShowToast(true);
      setToastVariant("success");
    } catch (error) {
      if(error?.response?.data?.error)
          setError(error?.response?.data?.error)
      setShowToast(true);
      setToastMessage("Error during file upload");
      setToastVariant("danger");
    } finally {
      setUploadLoader(false);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    const eventId = event ? event?.id : null;
    formik.setFieldValue("eventId", eventId);
  }, [event]);

  useEffect(() => {
    if (showToast) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showToast]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    //console.log("clicked");
  };
  //console.log(event?.race, "event races");
  return (
    <>
      <div className="content">
      {uploadLoader && <BlockingLoader />}
        {openModal && (
          <UploadModal
            options={excelData}
            handleProceed={handleModalProceed}
            handleCancel={() => setOpenModal(false)}
            loading={uploadLoader}
            error={uploadError}
            setUploadError={setUploadError}
          />
        )}
        <>
          <div className="p-3">
            <div className="row y-gap-20 justify-center items-center">
              <form
                className="col-xl-9 col-lg-9 mt-30 mx-auto absolute"
                id="reg"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <div className="d-flex flex-column col justify-content-center align-items-center">
                  <div className="col-md-6 mt-4">
                    <label className="text-16 text-dark-1 fw-bold mb-2">
                      Select the Race
                    </label>
                    {/* <input
                      type="file"
                      className="form-control"
                      id="file"
                      name="file"
                      onChange={async (event) => {
                        const file = event.currentTarget.files[0];
                        formik.setFieldValue("file", file);
                        const headers = await readExcelData(file);
                        console.log(headers, "headers");
                        setExcelData(headers);
                      }}
                      onBlur={formik.handleBlur}
                      required
                    /> */}
                    <select
                      className="form-control text-dark-1"
                      style={{ appearance: "auto" }}
                      onChange={async (event) => {
                        const value = event.target.value;
                        setError("")
                        formik.setFieldValue("race", value);
                      }}
                      onBlur={formik.handleBlur}
                    >
                      {/* TODO Change according to API result */}
                      <option value="">Select the race</option>
                      {event?.race.length &&
                        event?.race.map((race) => {
                          return <option value={race}>{race}</option>;
                        })}
                    </select>
                    {formik.touched.race && formik.errors.race ? (
                      <div className="text-danger">{formik.errors.race}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-4">
                    <label className="text-16 text-dark-1 fw-bold mb-2">
                      Upload Results File
                    </label>
                    <input
                      type="file"
                      className="form-control text-dark-1"
                      id="file"
                      name="file"
                      onChange={async (event) => {
                        const file = event.currentTarget.files[0];
                        formik.setFieldValue("file", file);
                        const headers = await readExcelData(file);
                        console.log(headers, "headers");
                        setExcelData(headers);
                        setError("")
                      }}
                      onBlur={formik.handleBlur}
                      required
                    />
                    {formik.touched.file && formik.errors.file ? (
                      <div className="text-danger">{formik.errors.file}</div>
                    ) : null}
                  </div>
                </div>
                {!!error &&
                  <div className="row mt-4 text-center">
                  <div className="col-12" style={{ color: 'red'}} >
                    {error}
               </div>
               </div>
                }
                <div className="row mt-4 text-center">
                  <div className="col-12">
                    <button className="btn btn-primary" type="submit">
                      Upload
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ToastComponent
      showToast={showToast}
      toastHeader="Upload Results"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
        </>
      </div>
    </>
  );
};

export default UploadResultsTab;
