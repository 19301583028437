import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ToastComponent from "./Common/Toast";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { baseUrl } from "../apiConfig";
import Race from "./Race";

const EventEdit = () => {
  const navigate = useNavigate();
//const {slug} = useParams();
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId")

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({ width: null, height: null });
  const [showRace, setShowRace] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [giveAways, setGiveAways] = useState({
    'Race T-shirt': false,
    "Finisher's Medal": false,
    'Bib Number with timing chip': false,
    'Goodie Bag': false,
    'Finisher e-Certificate': false,
    'Hydration Support': false,
  });

  const handleGiveAwayChange = (giveAway, checked) => {
    setGiveAways(prevState => ({
      ...prevState,
      [giveAway]: checked,
    }));
  };
  const formik = useFormik({
    initialValues: {
      eventName: "",
      location: "",
      aboutEvent: "",
      orgEmail: "",
      contactNum: "",
      secondaryContactNumber: "",
      regOpenDate: "",
      regCloseDate: "",
      tag: "",
      status: "",
      date: "",
      eventPicture: null,
      emailBanner: null,
      categories: [{ name: "", amount: "", minimumAge: "", maximumAge: "", gender: "" , distance: "", description: "" }],
      giveAway: [{name: ""}],
      url: "",
      race: [""]
    },
    validationSchema: Yup.object().shape({
      eventName: Yup.string().required("Event Name is required"),

      location: Yup.string().required("Location is required"),
//      aboutEvent: Yup.string().required("About Event is required"),
      // orgEmail: Yup.string()
      //   .email("Invalid email")
      //   .required("Email is required"),
      // regOpenDate: Yup.date().required("Registration Open Date is required"),
       //regCloseDate: Yup.date().required("Registration Close Date is required"),
      // tag: Yup.string().required("Tag is required"),
      date: Yup.date().required("Date is required"),
      eventPicture: Yup.mixed().required("Event Poster is required"),
      emailBanner: Yup.mixed().required("Email Banner is required"),
      // categories: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       name: Yup.string().required("Category Name is required"),
      //       amount: Yup.string().required("Registration Amount is required"),
      //       minimumAge: Yup.string().required("Minimum age is required"),
      //       maximumAge: Yup.string().required("Maximum age is required"),

      //       gender: Yup.string().required("Gender is required"),
      //       distance: Yup.string().required("Distance is required"),
      //       description: Yup.string().required("description is required"),


        //   })
        // )
        // .min(1, "At least one category must be added"),
        // giveAway: Yup.array()
        // .of(
        //   Yup.object().shape({
        //     name: Yup.string().required("Give Away is required"),
        //   })
        // ),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("userId", userId);
formData.append("eventName", values.eventName);
        const slug = values.eventName.replace(/\s+/g, '-');
formData.append("slug", slug);
        formData.append("location", values.location);
        formData.append("aboutEvent", values.aboutEvent);
        formData.append("orgEmail", values.orgEmail);
        formData.append("contactNum", values.contactNum);
        formData.append("secondaryContactNumber", values.secondaryContactNumber);
        if (values.regOpenDate) formData.append("regOpenDate", values.regOpenDate.toISOString());
        if (values.regCloseDate) formData.append("regCloseDate", values.regCloseDate.toISOString());
        formData.append("tag", values.tag);
        formData.append("status", values.status);
        formData.append("date", values.date);
        formData.append("eventPicture", values.eventPicture);
        formData.append("emailBanner", values.emailBanner);
        formData.append("url", values.url);
        values.race.forEach((race, index) => {
          formData.append(`race[${index}]`, race);
        });
        values.categories.forEach((category, index) => {
          formData.append(`category[${index}][name]`, category.name);
          formData.append(`category[${index}][amount]`, category.amount);
          formData.append(`category[${index}][minimumAge]`, category.minimumAge);
          formData.append(`category[${index}][maximumAge]`, category.maximumAge);
          formData.append(`category[${index}][gender]`, category.gender);
          formData.append(`category[${index}][distance]`, category.distance);
          formData.append(`category[${index}][description]`, category.description);
        });
        const selectedGiveAways = Object.entries(giveAways)
        .filter(([_, checked]) => checked)
        .map(([giveAway]) => ({ name: giveAway }));
      formData.append('giveAway', JSON.stringify(selectedGiveAways));

        // let response;
        //   response = await axios.put(`${baseUrl}events/edit-event?eventId=${event.id}`, formData, {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   });
        let response;
      
        response = await axios.post(`${baseUrl}events/create-event`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      
        if (response.status === 200) {
          setToastVariant("success");
          setToastMessage("Event created successfully")
          setShowToast(true);
          setFormSubmitted(true);
          setTimeout(()=>{
            navigate(`/events`);
          }, 1000)
            
            } else {
        
              const errorData = await response.json();
              console.error('Event creation failed:', errorData);
              setToastVariant("danger");
              setToastMessage("Event creation failed");
              setShowToast(true);
              setFormSubmitted(true);
            }
          }
          catch (error) {
            setToastVariant("danger");
            const errorMessage = error.response?.data?.error || "Error during event creation";
            setToastMessage(errorMessage)
            setShowToast(true);
            setFormSubmitted(true)
            window.scrollTo({top: 0, behavior:"smooth"})
          }
          finally {
            setIsLoading(false);
          }
        },
      });

useEffect(() => {
  const selectedGiveAways = Object.entries(giveAways)
    .filter(([_, checked]) => checked)
    .map(([giveAway]) => ({ name: giveAway }));
  formik.setFieldValue("giveAway", selectedGiveAways);
}, [giveAways]);

  
  useEffect(()=>{
    if(formSubmitted){
  window.scrollTo({top: 0, behavior:"smooth"})
    }
  }, [formSubmitted])



  const handleDayChange = (date) => {
    formik.setFieldValue("date", date instanceof Date ? date : new Date(date));
    formik.setFieldTouched('date', false);
  };
  const handleDateBlur = () => {
    formik.setFieldTouched('date', true);
  };

  const handleRegOpenDayChange = (date) => {
    formik.setFieldValue("regOpenDate", date instanceof Date ? date : new Date(date));
    formik.setFieldTouched('regOpenDate', false);
  };

  const handleRegOpenBlur = () => {
    formik.setFieldTouched('regOpenDate', true);
  };

  const handleRegCloseDayChange = (date) => {
    formik.setFieldValue("regCloseDate", date instanceof Date ? date : new Date(date));
    formik.setFieldTouched('regCloseDate', false);
  };
    const handleRegCloseBlur = () => {
    formik.setFieldTouched('regCloseDate', true);
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    
    if (file) {
      const fileSizeKB = file.size / 1024;
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
       if(fileSizeKB <= 5120){ 
          formik.setFieldValue("eventPicture", file);
          setSelectedFile(file);
          formik.setFieldError("eventPicture", "");
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
          
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              setImageDimensions({width: width, height: height})
            };
          };
          reader.readAsDataURL(file);
        }
        else
        {
          formik.setFieldError("eventPicture", "File size should not be exceeds more than 5MB");
        }
      } else {
        formik.setFieldError("eventPicture", "Please select a valid JPG or PNG file.");
      }
    }
  };
  const handleBannerChange = (event) => {
    const file = event.currentTarget.files[0];
    
    if (file) {
   formik.setFieldValue("emailBanner", file);
          setSelectedFile(file);
          formik.setFieldError("emailBanner", "");
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
          
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              setImageDimensions({width: width, height: height})
            };
          };
          reader.readAsDataURL(file);
        
   
    }
  };

  const handleCategoryChange = (index, key, value) => {
    const updatedCategories = [...formik.values.categories];
    updatedCategories[index][key] = value;

    const isDuplicate = updatedCategories.some(
      (category, i) => i !== index && category.name === value
    );

    if (isDuplicate) {
      formik.setFieldError(
        `categories[${index}].name`,
        "Category Name already exists. Please create category with another name"
      );
    } else {
      formik.setFieldValue("categories", updatedCategories);
    }
  };

  const addCategoryInput = () => {
    const newCategory = { name: "", amount: "", minimumAge: "", maximumAge: "", gender: "", distance: "", description: "" };
    formik.setFieldValue("categories", [
      ...formik.values.categories,
      newCategory,
    ]);
  };

  const removeCategory = (index) => {
    const updatedCategories = [...formik.values.categories];
    updatedCategories.splice(index, 1);
    formik.setFieldValue("categories", updatedCategories);
  };

  const showInputs = () => {
    setShowCategories(true);
  };
  const handleAddRaceBtnClick = () => {
    if (!showRace) setShowRace(true);
    else {
      formik.setFieldValue("race", [...formik.values.race, ""]);
    }
  };
  const [showCategories, setShowCategories] = useState(false);
  
  return (
    <>
    <style>
      {`
      .datepicker-no-outline:focus {
        outline: none;
      }
      .form-input {
        //position: relative;
      
        //display: flex;
      
        label {
          //position: absolute;
          top: 0;
          margin-top:5px;
          pointer-events: none;
          font-size: 14px;
        }
      
        textarea,
        input {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
        select {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          //padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
      
      input:not(:placeholder-shown) {
        padding-top: 0px;
      }
      textarea:not(:placeholder-shown) {
        padding-top: 5px;
      }
        textarea:focus ~ label,
        textarea:valid ~ label,
        input:focus ~ label,
        input:valid ~ label {
          transform: translateY(-10px);
        }
      }
      `}
    </style>
    <div className="container p-4">
    {isLoading ? (
        <div className="loading-overlay text-center d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <div className="spinner-border text-primary">
          </div>
        </div>
      ) : (
        <>
        <div className="row justify-content-center">
      <form
        className="col-xl-100 col-lg-11 mt-30"
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >

        <div className="row x-gap-40 y-gap-20">
          <div className="col-md-6 mt-3">
          <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">Event Name <span className="text-danger">*</span></label>
              <input
                type="text"
                id="eventname"
                className="form-control"
                name="eventName"
                value={formik.values.eventName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              </div>
              {formik.touched.eventName && formik.errors.eventName ? (
              <div className="text-danger">{formik.errors.eventName}</div>
            ) : null}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">Location <span className="text-danger">*</span></label>
              <input
                type="text"
                id="location"
                className="form-control"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
            </div>
            {formik.touched.location && formik.errors.location ? (
              <div className="text-danger">{formik.errors.location}</div>
            ) : null}
          </div>

          <div className="col-md-6 mt-3">
            <div className="form-input">
             <label className="text-16 text-dark-1 fw-bold">Event Date <span className="text-danger">*</span></label>
             </div>
             <div className="form-control" style={{lineHeight:"40px"}}>
              <DatePicker
                selected={formik.values.date}
                onChange={handleDayChange}
                format="DD/MM/YYYY"
                className="border-0 datepicker-no-outline"
                onBlur={handleDateBlur}
              /> 
 </div>
            {formik.touched.date && formik.errors.date ? (
              <div className="text-danger">{formik.errors.date}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <div>
              <label className="text-16 text-dark-1 fw-bold mt-3">Event Poster <span className="text-danger">*</span></label>
              </div>
              <input
              className="text-dark-1"
                type="file"
                id="eventpicture"
                name="eventPicture"
                onChange={handleFileChange}
                onBlur={formik.handleBlur}
                required
              />
             
               {selectedFile && (
          <div className="d-flex flex-inline x-gap-10">
            <p>Size: {Math.round(selectedFile.size / 1024)} KB;</p>
            <p>Width: {imageDimensions.width};</p>
            <p>Height: {imageDimensions.height}</p>
            
          </div>
        )}
              {formik.touched.eventPicture && formik.errors.eventPicture ? (
                <div className="text-danger">{formik.errors.eventPicture}</div>
              ) : null}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-input ">
            <label className="text-16 text-dark-1 fw-bold">
                Contact Number
              </label>
              <input
                type="text"
                id="conatctNum"
                className="form-control"
                name="contactNum"
                value={formik.values.contactNum}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {/^0|^(\+91)/.test(formik.values.contactNum) && (
              <div className="text-danger font-weight-bold">
                Do not start with 0 or +91
              </div>
            )}
            {formik.touched.contactNum && formik.values.contactNum && formik.values.contactNum.length !== 10 && (
  <div className="text-danger font-weight-bold">
    Please enter a valid 10-digit mobile number.
  </div>
)}
          </div>
          <div className="col-md-6">
          <div>
              <label className="text-16 text-dark-1 fw-bold mt-3">Email Banner <span className="text-danger">*</span></label>
              </div>
            <input
            className="text-dark-1"
              type="file"
              id="emailbanner"
              name="emailBanner"
              onChange={handleBannerChange}
              onBlur={formik.handleBlur}
              required
            />
              {formik.touched.emailBanner && formik.errors.emailBanner ? (
                <div className="text-danger">{formik.errors.emailBanner}</div>
              ) : null}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-input ">
            <label className="text-16 text-dark-1 fw-bold">
                Secondary Contact Number
              </label>
              <input
                type="text"
                id="secondaryContactNumber"
                className="form-control"
                name="secondaryContactNumber"
                value={formik.values.secondaryContactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {/^0|^(\+91)/.test(formik.values.secondaryContactNumber) && (
              <div className="text-danger font-weight-bold">
                Do not start with 0 or +91
              </div>
            )}
            {formik.values.secondaryContactNumber && formik.values.contactNum === formik.values.secondaryContactNumber &&(
              <div className="text-danger font-weight-bold">
              Contact number and secondary contact number cannot be the same.
              </div>
            )}
           {formik.touched.secondaryContactNumber && formik.values.secondaryContactNumber && formik.values.secondaryContactNumber.length !== 10 && (
  <div className="text-danger font-weight-bold">
    Please enter a valid 10-digit mobile number.
  </div>
)}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">
                Tag
              </label>
              <select
                id="tag"
                className="text-dark-1 border p-2 rounded-2 form-select"
                name="tag"
                value={formik.values.tag}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Please Select</option>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
                <option value="Fast Filling">Fast Filling</option>
              </select>
            </div>
            {formik.touched.tag && formik.errors.tag ? (
              <div className="text-danger">{formik.errors.tag}</div>
            ) : null}
          </div>

          <div className="col-md-6 mt-3">
            <div className="form-input ">
            <label className="text-16 text-dark-1 fw-bold">
                Registration Open Date
              </label>
              </div>
             <div className="form-control" style={{lineHeight:"40px"}}>
              <DatePicker
                selected={formik.values.regOpenDate}
                onChange={handleRegOpenDayChange}
                format="DD/MM/YYYY"
                className="border-0 datepicker-no-outline"
                onBlur={handleRegOpenBlur}
              />
             
            </div>
            {formik.touched.regOpenDate && formik.errors.regOpenDate ? (
              <div className="text-danger">{formik.errors.regOpenDate}</div>
            ) : null}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">
                Organizer Email
              </label>
              <input
                type="email"
                id="orgemail"
                className="form-control"
                name="orgEmail"
                value={formik.values.orgEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                //required
              />

            </div>
            {formik.touched.orgEmail && formik.errors.orgEmail ? (
              <div className="text-danger">{formik.errors.orgEmail}</div>
            ) : null}
          </div>
          <div className="col-md-6 mt-3">
          <div className="form-group form-input">
          <label className="text-16 text-dark-1 fw-bold">
              Status
            </label>
            <select
              id="status"
              className="text-dark-1 border p-2 rounded-2 form-select"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Please Select</option>
              <option value="UPCOMING">UPCOMING</option>
              <option value="OPENFORREGISTRATION">OPENFORREGISTRATION</option>
              <option value="REGISTRATIONCLOSED">REGISTRATIONCLOSED</option>
              <option value="EVENTDATEEXPIRED">EVENTDATEEXPIRED</option>
              <option value="RESULTSUPLOADED">RESULTSUPLOADED</option>
              <option value="PHOTOSUPLOADED">PHOTOSUPLOADED</option>
              <option value="ARCHIVED">ARCHIVED</option>
              </select>
          </div>
        </div>
       
          <div className="col-md-6 mt-3">
            <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">
                Registration Close Date
                </label>
              </div>
             <div className="form-control" style={{lineHeight:"40px"}}>
              <DatePicker
                inputClass=""
                selected={formik.values.regCloseDate}
                onChange={handleRegCloseDayChange}
                format="DD/MM/YYYY"
                className="border-0 datepicker-no-outline"
                onBlur={handleRegCloseBlur}
              />
            </div>
            {formik.touched.regCloseDate && formik.errors.regCloseDate ? (
              <div className="text-danger">{formik.errors.regCloseDate}</div>
            ) : null}
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">About Event</label>
            <CKEditor
              editor={ClassicEditor}
              data={formik.values.aboutEvent}
              onChange={(event, editor) => {
                formik.setFieldValue("aboutEvent", editor.getData());
              }}
            />
            </div>
            {formik.touched.aboutEvent && formik.errors.aboutEvent ? (
              <div className="text-danger">{formik.errors.aboutEvent}</div>
            ) : null}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-input">
            <label className="text-16 text-dark-1 fw-bold">
                External Link
              </label>
              <input
                type="text"
                id="url"
                className="form-control"
                name="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
 {formik.touched.url && formik.errors.url ? (
   <div className="text-danger">{formik.errors.url}</div>
 ) : null}
</div>
    </div>
    <div className="row y-gap-10 items-center">
    <div className="col-md-12">
          <div className="row x-gap-40 y-gap-10 items-center mt-2">
                      <div className="col-md-5">
          {!showCategories && (
            <button
              type="button"
              onClick={showInputs}
              className="button btn border p-2 text-dark-1 fw-bold rounded-3 "
            >
              Categories
            </button>
          )}
        </div>
</div>

<div className="row x-gap-40 items-center">
                      <div className="col-md-6">
            {showCategories && (
              <button
                type="button"
                onClick={addCategoryInput}
                className="button border btn p-2 text-dark-1 fw-bold rounded-3 "
              >
                Add Category
              </button>
            )}
          </div>
        </div>
        <div className="x-gap-20">
            {showCategories &&
              formik.values.categories.map((category, index) => (
                <div key={index}>
                  <div className="" style={{ marginTop: "0.5em" }}>
                    <div className="row x-gap-20 y-gap-10 align-items-end">
                      <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Category Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={category.name}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                            onBlur={formik.handleBlur}
                          />
                        </div>

                        {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].name && (
    <div className="text-danger">
      {formik.errors.categories[index].name}
    </div>
  )
)}
                      </div>

                      <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Gender</label>
                <select className='form-select text-dark-1' name="gender" id="gender" 
                onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "gender",
                                e.target.value
                              )
                            } onBlur={formik.handleBlur} 
                            value={category.gender}>
                  <option value="">Select Gender</option>
                  <option>MALE</option>
                  <option>FEMALE</option>
                  <option>BOTH</option>
                </select>                                
                {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].gender && (
    <div className="text-danger">
      {formik.errors.categories[index].gender}
    </div>
  )
)}
            </div>
            </div>
            <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            value={category.amount}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "amount",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].amount && (
    <div className="text-danger">
      {formik.errors.categories[index].amount}
    </div>
  )
)}
                      </div>
                      <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Minimum Age</label>
                          <input
                            type="text"
                            className="form-control"
                            value={category.minimumAge}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "minimumAge",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].minimumAge && (
    <div className="text-danger">
      {formik.errors.categories[index].minimumAge}
    </div>
  )
)}
                      </div>
                      <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Maximum Age</label>
                          <input
                            type="text"
                            className="form-control"
                            value={category.maximumAge}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "maximumAge",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].maximumAge && (
    <div className="text-danger">
      {formik.errors.categories[index].maximumAge}
    </div>
  )
)}
                      </div>
                      <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Distance</label>
                          <input
                            type="text"
                            className="form-control"
                            value={category.distance}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "distance",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].distance && (
    <div className="text-danger">
      {formik.errors.categories[index].distance}
    </div>
  )
)}
                      </div>
                      <div className="col-md-2">
                        <div className="form-group form-input">
                        <label className="text-16 text-dark-1 fw-bold">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            value={category.description}
                            onChange={(e) =>
                              handleCategoryChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        {formik.touched.categories && formik.errors.categories && Array.isArray(formik.errors.categories) && (
  formik.errors.categories[index] &&
  formik.errors.categories[index].description && (
    <div className="text-danger">
      {formik.errors.categories[index].description}
    </div>
  )
)}
                      </div>
                      <div className="col-md-2 mt-3">
                        <div className="form-group form-input">
                          <button
                            type="button"
                            className="border p-2 rounded-1 btn text-dark-1"
                            onClick={() => removeCategory(index)}
                          >
                    Remove
                          </button>
                        </div>
                      </div>
               
                    </div>
                  </div>
                </div>
              ))}
        </div>
        </div>
        <Race
            formik={formik}
            // race={formik.values.race}
            showRace={showRace}
            handleAddRaceBtnClick={handleAddRaceBtnClick}
          />
        <div className="col-md-12 mt-3">
        <div className="text-16 text-dark-1 fw-bold">Give Aways</div>
{Object.entries(giveAways).map(([giveAway, checked]) => (
  <>
  <div className="d-flex gap-3 align-items-start">
        <div key={giveAway}>
          <input
            type="checkbox"
            name={giveAway}
            className="form-check-input border p-2"
            id={giveAway}
            checked={checked}
            onChange={(e) => handleGiveAwayChange(giveAway, e.target.checked)}
          />
          </div>
          <div>
          <label htmlFor={giveAway} className="text-16 text-dark-1 fw-bold">{giveAway}</label>
          </div>
        </div>
        </>
      ))}
        </div>
        </div>
        <div className='mt-3 text-dark-1'>
<span className="text-danger">*</span> Indicates mandatory fields
</div>
        <div className="row x-gap-20 y-gap-20 pt-10">
          <div className="col-12">
            <button
            className="button border btn btn-primary px-4 py-2 text-light-1 rounded-3 mt-3" 
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      </div>
</>
      )}
      <ToastComponent
      showToast={showToast}
      toastHeader="Event Create"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />

      
      </div>
 </>
  );
};

export default EventEdit;
