import React,{useState, useEffect} from 'react'
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory ,{ textFilter, selectFilter }from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as XLSX from 'xlsx';
import DashboardHeader from './DashboardHeader';
import SidebarEvent from './SidebarEvent';
import "bootstrap-icons/font/bootstrap-icons.css";
import cellEditFactory from 'react-bootstrap-table2-editor';

import { Link, useLocation } from 'react-router-dom';
const LiveResults = ({isOpen,setIsOpen, slug, enhancedResults, updateResults}) => {
const location = useLocation();
const [results, setResults] = useState([])
   const [selectedRows, setSelectedRows] = useState([]);

   const [currentPage, setCurrentPage] = useState(1);
   const [perPage, setPerPage] = useState(20);
 const [event, setEvent] = useState(null);
 const [apiUrl, setApiUrl] = useState('');
 const [response, setResponse] = useState('');
 const [startlist, setStartlist] = useState([])
 const [editedFields, setEditedFields] = useState({
  ChipTime: "",
  MobileNumber: ""
});
const [alertMessage, setAlertMessage] = useState('');
const [showAlert, setShowAlert] = useState(false);

 const fetchData = async () => {
  const response = await axios.get(`${baseUrl}events/get-eventbyslug?slug=${slug}`);
  setEvent(response.data);
};

useEffect(() => {
  fetchData();
}, [slug]);

    
      const handleToggle = () => {
        setIsOpen(!isOpen);
      };

      const selectRow = {
        mode: "checkbox",
        headerColumnStyle: { verticalAlign: 'middle', textAlign: 'center' },
        onSelect: (row, isSelect) => {
          if (isSelect) {
            setSelectedRows([...selectedRows, row]);
          } else {
            setSelectedRows(selectedRows.filter((selectedRow) => selectedRow.id !== row.id));
          }
        },
      };
      

    //const [results, setResults] = useState([]);
    useEffect(() => {
      if (response) {
        const parsedResponse = JSON.parse(response);
        setResults(parsedResponse.Results);
      }
    }, [response]);
    console.log(results[0]?.Racers, "results")
    const handleApiRequest = async () => {
      try {
        const res = await fetch(apiUrl);
        const data = await res.json();
        setResponse(JSON.stringify(data, null, 2));
      } catch (error) {
        console.error('Error fetching data:', error);
        setResponse('Error fetching data: ' + error.message);
      }
    };
    const handleInputChange = (event) => {
      setApiUrl(event.target.value);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleApiRequest();
      }
    };
  
    const columns = [
      { dataField: 'Place', text: 'Place', editable: false, headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center'
      } },
      { dataField: 'Bib', text: 'Bib Number', editable: false, headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center',
      } },
      { 
        dataField: 'Name', 
        text: "Name", editable: false, headerStyle: { whiteSpace: 'nowrap', verticalAlign:"middle", textAlign:"center", width:"300px" },
      },
      { dataField: 'MobileNumber',  text: (
        <>
          Mobile Number <i className="fa fa-edit"></i>
        </>
      ), headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center'
      } },
      { dataField: 'Email', text: 'Email', editable: false, headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center'
      } },
      { dataField: 'ChipTime',  text: (
        <>
          Chip Time <i className="fa fa-edit"></i>
        </>
      ), headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center'
      } },
      {
        dataField: "SMSTriggered",
        text: 'SMS Triggered', 
        editable: false, 
        headerStyle: {
          verticalAlign: 'middle',
          textAlign: 'center'
        },
      }
      
    ];
    const rowStyle = {
      whiteSpace: 'nowrap',
      verticalAlign:"middle",
      textAlign:"center"
    };
     
  useEffect(()=> {
    const fetchResults = async() =>{
      const res = await axios.get(`${baseUrl}events/get-startlist?event_slug=${slug}`)
      setStartlist(res?.data);
    }
    fetchResults();
  }, [slug]);
  const getEmailAndInfo = (bib) => {
    const racer = startlist.find(item => item.Bib === bib);
    if (racer) {
      return { email: racer.Email, info1: racer["Info 1"], SMSTriggered: racer.SMSTriggered  };
    }
    return { email: null, info1: null, SMSTriggered: null };
  };
  
  const exportToDB = async () => {
    try {
      if (enhancedResults && enhancedResults.length > 0) {
        await axios.delete(`${baseUrl}events/delete-live-results`);
        await axios.post(`${baseUrl}events/upload-excelToDB`, enhancedResults);
        setAlertMessage('Data successfully exported to the database.');
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } else {
        console.log('No data available to export to Excel.');
      }
    } catch (error) {
      setAlertMessage('Error saving results to the database.');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
const handleCellEditing = async (newValue, row, column) => {
  const fieldName = column.dataField;
  setEditedFields(prevState => ({
      ...prevState,
      [fieldName]: newValue
  }));

  try {
      const updatedResult = { ...row, [column.dataField]: newValue };
      await axios.put(`${baseUrl}events/edit-result?bibNumber=${row.Bib}`, updatedResult);
      localStorage.setItem(`${row.Bib}_${column.dataField}`, newValue);
  } catch (error) {
      console.error("Error in updating result", error);
  }
  
};

useEffect(() => {
  const calculateEnhancedResults = results && results.length > 0
    ? results[0]?.Racers.map(racer => {
        const { email, info1, SMSTriggered } = getEmailAndInfo(racer.Bib);
        return { ...racer, Email: email, MobileNumber: info1, SMSTriggered: SMSTriggered };
      })
    : [];

  const enhancedResultsWithEdits = calculateEnhancedResults.map(racer => {
    const editedChipTime = localStorage.getItem(`${racer.Bib}_ChipTime`);
    const editedMobileNumber = localStorage.getItem(`${racer.Bib}_MobileNumber`);
    if (editedChipTime !== null) {
      racer.ChipTime = editedChipTime;
    }
    if (editedMobileNumber !== null) {
      racer.MobileNumber = editedMobileNumber;
    }
    return racer;
  });

  updateResults(enhancedResultsWithEdits);
}, [results]);

  return (
    <>
    <style>{`
    body {
      overflow-x: hidden;
    }
    .sticky-header th {
      position: sticky;
      vertical-align:middle;
       padding-top:5px;
       padding-bottom:5px;
       cursor:pointer;
       white-space:nowrap;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }

    @media (max-width: 767px) {
      .mobile-width {
        width: 50%;
        margin: 0 auto;
      }
    }
    .react-bootstrap-table-pagination {
      position: sticky;
     left: 0%;
      transform: translateX(0%);
      z-index: 1000;
    }
    .react-bootstrap-table th i.bi-sort-alpha-down,
.react-bootstrap-table th i.bi-sort-alpha-down-alt {
  font-size: 1em;
}
  `}</style>
   
<div className="content">
    <div className="dashboard">
      <div className="dashboard__main">
          <div className="dashboard__content bg-light-2">
              <div className="chart_responsive">
                  <div className="rounded-4 bg-white shadow-3">
                      <div className="container-fluid my-4">
                          <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">
                          {showAlert && (
                        <div className="alert alert-primary text-center w-50 mx-auto" role="alert">
                          {alertMessage}
                        </div>
                      )}
                          <div className="row justify-content-center pt-3 px-3">
    <div className="col-sm-12 col-md-6 col-lg-4">
      <div className="d-flex flex-row gap-3 mb-3">
        <input
        type="text"
          id="filterText"
          name="filterText"
          placeholder='Please enter json api url'
          value={apiUrl}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          className='form-control border'
            />
        <div className="input-group-append">
          <button className="btn border" type="button"
              onClick={handleApiRequest}
              >
           <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#697488" d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4z"></path></svg>
          </button>
        </div>
      </div>
    </div>
  </div>

<div className='mt-3 d-flex flex-column flex-md-row gap-0 gap-md-2 justify-content-end'>                       
  <button className="btn btn-primary mb-3" onClick={exportToDB}>Export to DB</button>
  <button className="btn btn-primary mb-3">Send</button>
  </div>
                                      <div style={{ maxHeight: '450px', overflowY: 'auto'}} className="table-responsive">
                                      {enhancedResults?.length > 0 ? (
                                          
        <BootstrapTable
          keyField='Place'
          data={enhancedResults?.filter(racer => racer.ChipTime !== "")}
          columns={columns}
          selectRow={selectRow}
          filter={filterFactory()}
          striped
          hover
          condensed
          rowStyle={rowStyle}
          cellEdit={ cellEditFactory({ 
            mode: 'click',
            afterSaveCell: (oldValue, newValue, row, column) => handleCellEditing(newValue, row, column)
           }) }
          headerClasses='sticky-header'
          pagination={paginationFactory({
            page: 1,
            sizePerPage: 20,
            totalSize: results[0]?.Racers?.length,
            onPageChange: (page, sizePerPage) => {
              setCurrentPage(page);
            },
            onSizePerPageChange: (page, sizePerPage) => {
              setCurrentPage(1);
              setPerPage(sizePerPage);
            },
          })}
        />
      ) : (
      null
      )}
                                                                     
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  </div>
  </>


  )
}

export default LiveResults;