// RunnerDetailsModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory ,{ textFilter, selectFilter }from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BreadCrumb from './BreadCrumb';

const RunnerDetailsModal = ({ runner, onClose }) => {
    const columns = [
        { dataField: 'firstName', text: 'FirstName', sort: true },
        { dataField: 'lastName', text: 'LastName', sort: true },
        { dataField: 'email', text: 'Email', sort: true },
        { dataField: 'mobileNumber', text: 'MobileNumber', sort: true },
        { dataField: 'gender', text: 'Gender', sort: true },
        { dataField: 'dateOfBirth', text: 'DateOfBirth', sort: true},
        { dataField: 'categoryName', text: 'Category', sort: true },
        { dataField: 'bibNumber', text: 'Bib Number', sort: true },
        { dataField: 'nameOfTheBib', text: 'NameOfTheBib', sort: true },
        { dataField: 'contactName', text: 'ContactName', sort: true },
        { dataField: 'contactNumber', text: 'ContactNumber', sort: true },
        { dataField: 'bloodGroup', text: 'Blood Group', sort: true },
        { dataField: 'chipCode', text: 'RFID', sort: true },
        { dataField: 'runnerClub', text: 'Runner Club', sort: true },
        { dataField: 'tShirtSize', text: 'TShirt Size', sort: true },
        { dataField: 'pincode', text: 'Pin code', sort: true },
        { dataField: 'state', text: 'State', sort: true },
        { dataField: 'medicalIssue', text: 'Medical Issue', sort: true },
        { dataField: 'address', text: 'Address', sort: true },
      ]    
      const transformedData = Object.keys(runner).map((key) => ({
        key,
        value: runner[key],
      }));   
  return (
    <Modal show={true} onHide={onClose}    dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Runner Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  {runner && (
    <div className="row">
    <div className="col">
      <table className="table table-bordered">
        <tbody>
          {columns.map(column => (
            <tr key={column.dataField}>
              <td><strong>{column.text}:</strong></td>
              <td>{runner[column.dataField]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  
    )}
</Modal.Body>

  <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close 
        </Button>
      </Modal.Footer>
     </Modal>
  );
};

export default RunnerDetailsModal;
