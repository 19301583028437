import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import DashboardHeader from "../DashboardHeader";
import BreadCrumb from "../BreadCrumb";
import Sidebar from "../Sidebar";

import { deleteRunnersClub, getRunnersClub } from "../../api/runnersClub";
import BlockingLoader from "../Common/Loader";
import Table from "../Common/Table";
import { headerStyle } from "../../utils/tableStyles";

const RunnerClubList = () => {
  const { slug } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [runnerClub, setRunnerClub] = useState([]);

  const fetchRunnerClub = async () => {
    try {
      setLoading(true);
      const response = await getRunnersClub();
      setRunnerClub(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRunnerClub();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async (runnerClubId) => {
    try {
      setLoading(true);
      const resp = await deleteRunnersClub({
        runnerClubId: runnerClubId,
      });
      if (resp?.data.message) await fetchRunnerClub();
    } catch (error) {
      console.error("Error deleting coupon:", error);
    } finally {
      setLoading(false);
    }
  };

  const tableHeaders = [
    {
      dataField: "name",
      text: "Runners Club Name",
      headerStyle: headerStyle,
    },
    {
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-row justify-content-center">
            <Link
              className="btn text-secondary border-0"
              to="/create-runnerclub"
              state={{ row }}
            >
              <i className="fa fa-edit"></i>
            </Link>
            <button
              className="btn text-secondary border-0"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
      headerStyle: headerStyle,
    },
  ];

  return (
    <>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />

      <div className="content">
        <BreadCrumb task={"Runners Club"} />
        <div className="d-flex flex-column flex-md-row justify-content-end gap-md-5 py-4">
          <Link
            className="btn border btn-primary mb-3 mobile-width"
            to="/create-runnerclub"
          >
            Create
          </Link>
        </div>
        {loading ? (
          <BlockingLoader />
        ) : (
          <Table
            keyField="id"
            data={runnerClub}
            columns={tableHeaders}
            // filter={filterFactory()}
            // headerClasses="sticky-header"
            // pagination={paginationFactory({
            //   page: 1,
            //   sizePerPage: 20,
            //   totalSize: runnerClub.length,
            //   onPageChange: (page, sizePerPage) => {
            //     setCurrentPage(page);
            //   },
            //   onSizePerPageChange: (page, sizePerPage) => {
            //     setCurrentPage(1);
            //     setPerPage(sizePerPage);
            //   },
            // })}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            currentPage={currentPage}
            perPage={perPage}
          />
        )}
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
};

export default RunnerClubList;
