import React, { useState, useEffect } from "react";

import axios from "axios";
import { baseUrl } from "../../apiConfig";

const CouponsUsedPerCategory = ({ slug }) => {
  console.log(slug, "slug");

  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);
  const [coupons, setCoupons] = useState({});
  const fetchCoupons = async () => {
    try {
      const categoryNames = event?.category.map((category) => category.name);
      const filteredUsers = users.filter(
        (user) =>
          categoryNames.includes(user.categoryName) && user.couponCode !== null
      );
      const uniqueCouponCodes = [
        ...new Set(filteredUsers.map((user) => user.couponCode)),
      ];
      const couponCountPerCategory = {};
      filteredUsers.forEach((user) => {
        const userCategory = user.categoryName;
        const userCouponCode = user.couponCode;
        if (uniqueCouponCodes.includes(userCouponCode)) {
          if (!couponCountPerCategory[userCategory]) {
            couponCountPerCategory[userCategory] = 0;
          }
          couponCountPerCategory[userCategory] += 1;
        }
      });

      console.log(couponCountPerCategory, "couponCountPerCategory");
      setCoupons(couponCountPerCategory);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [event]);
  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    const response = await axios.get(
      `${baseUrl}users/users?eventId=${event.id}`
    );
    setUsers(response.data);
  };

  useEffect(() => {
    if (event) {
      fetchUsers();
    }
  }, [event]);
  console.log(users, "users");

  useEffect(() => {
    fetchCoupons();
  }, [event]);

  return (
    <div className="p-3">
      {Object.entries(coupons).length === 0 ? (
        <h3 className="text-center p-3 text-dark-1">No data available</h3>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped text-center">
            <thead>
              <tr style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}>
                <th>Category</th>
                <th>Coupons Used</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(coupons).map(([category, count]) => (
                <tr
                  style={{ verticalAlign: "middle", whiteSpace: "nowrap" }}
                  key={category}
                >
                  <td>{category}</td>
                  <td>{count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CouponsUsedPerCategory;
