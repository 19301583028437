/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import BreadCrumb from "./BreadCrumb";
import BootstrapTable from "react-bootstrap-table-next";
import Sidebar from "./Sidebar";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import BlockingLoader from "./Common/Loader";
import { getSubscribers } from "../api/subscribers";
import { saveAs } from "file-saver";
const SubscribersList = () => {
  const { slug } = useParams();
  const [subscribers, setSubscribers] = useState([]);
  const [subscribersLoading, setSubscribersLoading] = useState("");

  const [subscribersError, setSubscribersError] = useState("");

  const fetchSubscribers = async () => {
    try {
      setSubscribersError("");
      setSubscribersLoading(true);
      const response = await getSubscribers();
      setSubscribers(response.data);
    } catch (error) {
      setSubscribersError(error.message);
    } finally {
      setSubscribersLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscribers();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const downloadSubscribers = () => {
      const subscribersList = subscribers.map((subscriber) => subscriber.email)
        .join("\n");
      const blob = new Blob([subscribersList], { type: "text/csv" });
      saveAs(blob, `subscribers.csv`);
    
  };

  const tableHeaders = [
    {
      dataField: "email",
      text: "Subscribers",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
        width: "auto",
      },
    },
  ];

  const rowStyle = (row, rowIndex) => {
    return {
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      width: "auto",
      textAlign: "center",
    };
  };

  return (
    <>
      <style>
        {`
          .sticky-header th {
            position: sticky;
            top: 0;
            background-color: #fff; 
            z-index: 1; 
          }
          .react-bootstrap-table td{
            padding-left: 1em;
          }
        `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      <div className="content">
        <BreadCrumb task={"Subscribers"} />
        {subscribersLoading ? 
     <BlockingLoader /> : 
        <>
<div className="d-flex justify-content-end my-3">
  <button className="btn btn-primary" onClick={downloadSubscribers}>Export CSV</button>
</div>
        {tableHeaders.length > 0 && (
          <>
          <div>Total subscribers: <span style={{fontWeight: "bold"}}>{subscribers.length}</span></div>
               <PaginationProvider
               pagination={ paginationFactory({
                 custom: true,
                 sizePerPage:20,
totalSize: subscribers.length
               }) }
             >
               {
                 ({
                   paginationProps,
                   paginationTableProps
                 }) => (
                   <div>
                    <div style={{ overflowX:"auto", marginTop:"15px"}}>
                     <PaginationListStandalone
                       { ...paginationProps }
                     />
                     </div>
          <div
            style={{ maxHeight: "450px", overflowY: "auto" }}
            className="table-responsive w-75 mx-auto"
          >
            <BootstrapTable
              keyField="id"
              data={subscribers}
              columns={tableHeaders}
              rowStyle={rowStyle}
              filter={filterFactory()}
              headerClasses="sticky-header"
              { ...paginationTableProps }
              bootstrap4
              striped
              hover
              condensed
            />
          </div>
          </div>
    )
  }
</PaginationProvider>
</>
        )}
        </>
}
      </div>
    </>
  );
};

export default SubscribersList;
