import React ,{ useState} from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExportCsv = ({runners,isOpen,setIsOpen, tableHeaders}) => {
  console.log(tableHeaders, "tableHeaders")
    const [selectedColumns, setSelectedColumns] = useState([]);
    console.log(selectedColumns, "selectedColumns")
    const [format, setFormat] = useState('CSV');
    const handleExportCSV = () => {
        let data;
        if (format === 'CSV') {
          data = convertToCSV(runners, selectedColumns);
        } else if (format === 'XLSX') {
          data = convertToXLSX(runners, selectedColumns);
        }
        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `exported-data.${format.toLowerCase()}`);
        handleCloseExportModal();
      };
    
      const convertToCSV = (data, selectedColumns) => {
        const headers = selectedColumns.map(column => customizedColumns[column] || column).join(',') + '\n';
        const csvData = data.map(item => selectedColumns.map(col => item[col]).join(',')).join('\n');
        setSelectedColumns([])
        return headers + csvData;
      };
    
      const convertToXLSX = (data, selectedColumns) => {
        const worksheet = XLSX.utils.json_to_sheet(data.map(item => selectedColumns.reduce((acc, col) => ({ ...acc, [customizedColumns[col] || col]: item[col] }), {})));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        setSelectedColumns([])
        return blob;
      };
      const handleCloseExportModal = () => {
        setSelectedColumns([])
        setIsOpen(false);
      };
      const customizedColumns = {
        firstName:'First Name',
        lastName:'Last Name',
        email:'Email',
        mobileNumber:'Mobile Number',
        gender:'Gender',
        dateOfBirth:'Date Of Birth',
        tShirtSize:'T-Shirt Size',
        nameOfTheBib:'Name On The Bib',
          categoryName:'Category',
          createdAt:'Time Of Registration',
          bloodGroup:'Blood Group',
            contactName:'Contact Name',
            contactNumber:'Contact Number',
            medicalIssue:'Medical Issue',
            runnerClub:'Runner Club',
            distance:'Distance',
            bibNumber:'Bib Number',
            chipCode:'Chip Code',
            runnerId: 'Runner Id',
              isDistributed: 'Is Distributed',
              distributedBy: 'Distributed By',
                isIdVerified: 'Is Id Verified',
                qrImageUrl: 'Qr Image Url',
                  isQRCodeSend: 'QR Code Send',
                    address: 'Address',
                    age: "Age",
                    isResultSMSSend: "Is Result SMS Send",
                    disorders: 'Disorders',
                        chronicIssues: "Chronic Issues"
     
}
  return (
    <div>
    <Modal show={isOpen} onHide={handleCloseExportModal} size="md">
    <Modal.Header closeButton>
      <Modal.Title>Export Options</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group controlId="exportFormat">
          <Form.Label>Select Format:</Form.Label>
          <Form.Control as="select" value={format} onChange={(e) => setFormat(e.target.value)}>
            <option value="CSV">CSV</option>
            <option value="XLSX">XLSX</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="exportColumns">
          <Form.Label>Select Columns:</Form.Label>
          {tableHeaders.map((column) => (
            <Form.Check
              key={column}
              type="checkbox"
              label={customizedColumns[column] || column}
              checked={selectedColumns.includes(column)}
              onChange={() => {
                if (selectedColumns.includes(column)) {
                  setSelectedColumns(selectedColumns.filter((col) => col !== column));
                } else {
                  setSelectedColumns([...selectedColumns, column]);
                }
              }}
            />
          ))}
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseExportModal}>
        Cancel
      </Button>
      <Button variant="primary" onClick={handleExportCSV}>
        Export
      </Button>
    </Modal.Footer>
  </Modal>
    </div>
  )
}

export default ExportCsv
