import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Events from "./components/Events";
import Metrics from "./components/Metrics";
import Sheets from "./components/Sheets";
import AddSheet from "./components/AddSheet";
import SingleEvent from "./components/SingleEvent";
import BibGeneration from "./components/BibGeneration";
import EventEdit from "./components/EventEdit";
import CRM from "./components/crm";
import UploadResults from "./components/UploadResults";
import UploadCertificate from "./components/UploadCertificate";
import UploadFinisherBadge from "./components/UploadFinisherBadge";
import UploadWatermark from "./components/UploadWatermark";
import CertificatesList from "./components/CertificatesList";
import SubscribersList from "./components/SubscribersList";
import Register from "./components/Register";
import Results from "./components/Results";
import EventCreateForResults from "./components/EventCreateForResults";

import "./global.scss";
import CouponDashboard from "./components/Coupons/CouponDashboard";
import RunnerClubList from "./components/RunnersClub/RunnerClubList";
import CreateCoupon from "./components/Coupons/CreateCoupon";
import BulkCoupon from "./components/Coupons/BulkCoupon";
import CreateRunnerClub from "./components/RunnersClub/CreateRunnerClub";
import UsageCoupon from "./components/Coupons/UsageCoupon";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="events" element={<Events />} />
          <Route path="metrics" element={<Metrics />} />
          <Route path="event/:slug" element={<SingleEvent />} />
          <Route path="sheets" element={<Sheets />} />
          <Route path="add-sheet" element={<AddSheet />} />
          <Route path="bibgeneration/:slug" element={<BibGeneration />} />
          <Route path="event-edit" element={<EventEdit />} />
          <Route path="crm/:slug" element={<CRM />} />
          <Route path="coupon-dashboard/:slug" element={<CouponDashboard />} />
          <Route path="runnerclub-list" element={<RunnerClubList />} />
          <Route path="create-coupon" element={<CreateCoupon />} />
          <Route path="bulk-coupon" element={<BulkCoupon />} />
          <Route path="/upload-results" element={<UploadResults />} />
          <Route path="/upload-certificate" element={<UploadCertificate />} />
          <Route path="/upload-finisherbadge" element={<UploadFinisherBadge />} />
          <Route path="/upload-watermark" element={<UploadWatermark />} />
          <Route path="/certificates-list" element={<CertificatesList />} />
          <Route path="/subscribers-list" element={<SubscribersList />} />
          <Route path="create-runnerclub" element={<CreateRunnerClub />} />
          <Route path="register/:slug" element={<Register />} />
          <Route path="usage-coupon/:slug" element={<UsageCoupon />} />
          <Route path="results/:slug" element={<Results />} />
          <Route path="create-event" element={<EventCreateForResults />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
