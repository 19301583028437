import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import { baseUrl } from "../apiConfig";
import axios from "axios";
import ToastComponent from "./Common/Toast";
import { getEvents } from "../api/events";
import { getSheets } from "../api/sheets";
import BlockingLoader from "./Common/Loader";
const Sheets = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sheets, setSheets] = useState([]);

  const [events, setEvents] = useState([]);

  const [eventsLoading, setEventsLoading] = useState(false);
  const [eventsError, setEventsError] = useState("");

  const [sheetsLoading, setSheetsLoading] = useState(false);
  const [sheetsError, setSheetsError] = useState("");

  const fetchData = async () => {
    try {
      setEventsError("");
      setEventsLoading(true);
      const response = await getEvents();
      setEvents(response.data);
    } catch (error) {
      console.log("Error during fetching events", error);
      setEventsError(error.message);
    } finally {
      setEventsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [toggleStates, setToggleStates] = useState({});

  const handleToggleMenu = (itemId) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const fetchSheets = async () => {
    try {
      setSheetsError("");
      setSheetsLoading(true);
      const response = await getSheets();
      setSheets(response.data);
    } catch (error) {
      setSheetsError(error.message);
      console.error("Error fetching data:", error);
      throw error;
    } finally {
      setSheetsLoading(false);
    }
  };

  useEffect(() => {
    fetchSheets();
  }, []);

  const handleResync = async (eventId) => {
    try {
      const response = await axios.put(
        `${baseUrl}events/${eventId}/resync`,
        null,
        {
          headers: {
            role: "super admin",
          },
        }
      );
      console.log(response.data);
      setToastVariant("success");
      setToastMessage("Resync successful");
      setShowToast(true);
    } catch (error) {
      console.error("Error resyncing:", error);
      setToastVariant("danger");
      setToastMessage("Failed to resync");
      setShowToast(true);
    }
  };

  useEffect(() => {
    if (showToast) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showToast]);

  return (
    <>
      <style>
        {`
      
      .toggle {
       position:relative; 
      }
    .dropdown-items {
       display:flex;
       flex-direction:column;
       position:absolute;
       right:0px;
       width:170px;
       z-index:1;
       white-space:nowrap;
       background-color:white;
       padding:0em 0em 0em 0.5em;
      }
   
    .dropdown-items div:hover {
      color: inherit;
      background-color:#697488;
      padding:0.1em;
      border-radius:3px;
      color:white;
      cursor:pointer;
  }
    `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />

      <Sidebar isOpen={isOpen} handleToggle={handleToggle} />
{eventsLoading && <BlockingLoader/>}
      <div className="content">
        <div className="row y-gap-20 justify-content-center items-center">
          <div className="row y-gap-30">
            {events.map((item) => (
              <div className="col-lg-3 col-sm-6" key={item?.id}>
                <div className="hotelsCard__image">
                  <div className="cardImage inside-slider">
                    <Link
                      to={`/event/${item.slug}`}
                      className="hotelsCard -type-1 hover-inside-slider ratio"
                    >
                      <img
                        src={item?.eventPicture}
                        className="img-thumbnail"
                        alt="Event"
                      />
                    </Link>
                    <div className="cardImage__leftBadge">
                      <div
                        className={`px-20 py-1 rounded-right-4 text-12 lh-16 fw-500 uppercase ${
                          isTextMatched(item?.tag, "Open")
                            ? "bg-dark-1 text-white"
                            : ""
                        } ${
                          isTextMatched(item?.tag, "Closed")
                            ? "bg-blue-1 text-white"
                            : ""
                        } 
                    } ${
                      isTextMatched(item?.tag, "Fast Filling")
                        ? "bg-brown-1 text-white"
                        : ""
                    } 
                    `}
                      >
                        {item?.tag}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hotelsCard__content">
                  <div className="d-flex flew-row justify-content-between mt-2">
                    <div>
                      <Link
                        to={`/event/${item.slug}`}
                        className="hotelsCard -type-1"
                      >
                        <h5 className="hotelsCard__title text-14 fw-500">
                          <span>{item?.eventName}</span>
                        </h5>
                        <p className="text-14">{item?.location}</p>
                        <p className="text-14">{item?.date?.split("T")[0]}</p>
                      </Link>
                    </div>
                    <div
                      className="toggle"
                      onClick={() => handleToggleMenu(item.id)}
                    >
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2em"
                          height="2em"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M144 128a16 16 0 1 1-16-16a16 16 0 0 1 16 16m-84-16a16 16 0 1 0 16 16a16 16 0 0 0-16-16m136 0a16 16 0 1 0 16 16a16 16 0 0 0-16-16"
                          ></path>
                        </svg>
                      </div>
                      {toggleStates[item.id] && (
                        <div className="dropdown-items">
                          {sheets
                            .filter((sheet) => sheet.eventId === item.id)
                            .map((sheet) => (
                              <div key={sheet.id}>{sheet.sheetId}</div>
                            ))}
                          <div></div>
                          <div onClick={() => handleResync(item?.id)}>
                            resync
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ToastComponent
      showToast={showToast}
      toastHeader="Resync"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  );
};

export default Sheets;

function isTextMatched(tag, match) {
  if (tag && match && typeof tag === "string" && typeof match === "string") {
    const lowercaseTag = tag.toLocaleLowerCase();
    const lowercaseMatch = match.toLocaleLowerCase();

    return lowercaseTag === lowercaseMatch;
  }
  return false;
}
