import React, { useState, useEffect, useMemo } from "react";
//import PaymentInfo from "./PaymentInfo";
//import OrderSubmittedInfo from "./OrderSubmittedInfo";
import axios from "axios";
import { useParams, redirectDocument, useLocation } from "react-router-dom";
import { baseUrl } from "../apiConfig";
import { useFormik } from "formik";
import { Toast, Modal, Button } from "react-bootstrap";
import CustomerInfo from "./CustomerInfo";
import * as Yup from "yup";
import DashboardHeader from "./DashboardHeader";
import SidebarEvent from "./SidebarEvent";
import moment from "moment";
import Sidebar from "./Sidebar";
const Index = () => {
  const [formValues, setFormValues] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const location = useLocation();
  const state = location.state || {};
  const runnerData = state.row;
  console.log(runnerData, "runnerData");

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryNames, setCategoryNames] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [event, setEvent] = useState(null);
  const [categoryMinimumAge, setCategoryMinimumAge] = useState(0);
  const [categoryMaximumAge, setCategoryMaximumAge] = useState(0);
  const [gender, setGender] = useState("");
  const [editedFields, setEditedFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        //setIsLoading(true);
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
        if (response.data?.category && response.data?.category.length > 0) {
          const names = response.data.category.map((category) => category.name);
          setCategoryNames(names);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    const eventId = event ? event?.id : null;
    formik.setFieldValue("eventId", eventId);
  }, [event]);
  const calculateAge = useMemo(() => {
    return (dateOfBirth) => {
      const birthDate = new Date(dateOfBirth);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        return age - 1;
      }

      return age;
    };
  }, []);

  const getEditedFields = (initialValues, newValues) => {
    const editedFields = [];

    for (const key in newValues) {
      if (newValues[key] !== initialValues[key]) {
        if (newValues[key] === undefined || initialValues[key] === undefined) {
          continue;
        }

        let oldValue = initialValues[key];
        let newValue = newValues[key];

        if (key === "dateOfBirth") {
          oldValue = new Date(oldValue).toDateString();
          newValue = new Date(newValue).toDateString();
          if (oldValue === newValue) {
            continue;
          }
        } else {
          if (!isNaN(Date.parse(oldValue))) {
            oldValue = new Date(oldValue).toDateString();
          }
          if (!isNaN(Date.parse(newValue))) {
            newValue = new Date(newValue).toDateString();
          }
        }

        editedFields.push({ field: key, oldValue, newValue });
      }
    }

    return editedFields;
  };

  const formik = useFormik({
    initialValues: {
      firstName: formValues?.firstName || "",
      lastName: formValues?.lastName || "",
      email: formValues?.email || "",
      mobileNumber: formValues?.mobileNumber || "",
      gender: formValues?.gender || "",
      runnerClub: formValues?.runnerClub || "None",
      offlineTransactionId: formValues?.offlineTransactionId || "",
      dateOfBirth: formValues?.dateOfBirth || "",
      tShirtSize: formValues?.tShirtSize || "",
      nameOfTheBib: formValues?.nameOfTheBib || "",
      bloodGroup: formValues?.bloodGroup || "",
      contactName: formValues?.contactName || "",
      contactNumber: formValues?.contactNumber || "",
      street: "",
      address: formValues?.address || "",
      city: formValues?.city || "",
      pincode: formValues?.pincode || "",
      state: formValues?.state || "",
      country: "",
      medicalIssue: formValues?.medicalIssue || "",
      categoryName: formValues?.categoryName || "",
      acceptedTerms: formValues?.acceptedTerms || "",
      couponCode: formValues?.couponCode || "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      categoryName: Yup.string().required("Category Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      mobileNumber: Yup.string()
        .required("Mobile Number is required")
        .matches(/^(?!0|(\+91))\d{10}$/, {
          message:
            "Mobile Number should not start with 0 or +91 and should be 10 digits",
        }),
      gender: Yup.string().test(
        "valid-gender",
        `Selected category allows only ${gender}S`,
        function (gen) {
          if (
            formik.values.categoryName &&
            gen &&
            gender &&
            gender !== "BOTH"
          ) {
            console.log(
              gen.toLowerCase(),
              "gender 1",
              `${gender.toLowerCase()}`,
              "gender 2"
            );
            return gen.toLowerCase() === gender.toLowerCase();
          }
          return true;
        }
      ),
      //.required("Geder field is required")
      runnerClub: Yup.string().required("Runner Club is required"),

      //offlineTransactionId: Yup.string().required("Offline Transaction is required"),
      dateOfBirth: Yup.date()
        .max(new Date(), "Date of Birth must be in the past")
        .test(
          "valid-age",
          `Age should be in between ${categoryMinimumAge} and ${categoryMaximumAge}`,
          function (date) {
            const age = calculateAge(date);
            return (
              age >= parseInt(categoryMinimumAge) &&
              age <= parseInt(categoryMaximumAge)
            );
          }
        )
        .required("Date of Birth is required"),
      tShirtSize: Yup.string().required("t Shirt Size is required"),
      nameOfTheBib: Yup.string().required("Name on the Bib is required"),
      bloodGroup: Yup.string().required("Blood Group is required"),
      contactName: Yup.string().required("Contact Name is required"),
      contactNumber: Yup.string()
        .required("Contact Number is required")
        .matches(/^(?!0|(\+91))\d{10}$/, {
          message:
            "Contact Number should not start with 0 or +91 and should be 10 digits",
        })
        .test(
          "not-same-as-mobile",
          "Contact number and emergency contact number cannot be the same.",
          function (value) {
            return value !== this.parent.mobileNumber;
          }
        ),
      //street: Yup.string().required("This field is required"),
      //city: Yup.string().required("This field is required"),
      pincode: Yup.string().required("Pincode is required"),
      state: Yup.string().required("State is required"),
      //country: Yup.string().required("This field is required"),
      address: Yup.string().required("Address is required"),
      medicalIssue: Yup.string(),
      acceptedTerms: Yup.boolean()
        .oneOf([true], "You must agree to the terms")
        .required("You must agree to the terms"),
      couponCode: Yup.string().max(
        10,
        "Coupon code must not exceed more than 10 letters"
      ),
    }),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setIsSubmittingForm(true);
        setIsLoading(true);
        const age = calculateAge(values.dateOfBirth);
        let response;
        if (runnerData) {
          response = await axios.put(
            `${baseUrl}users/edit-user?userId=${runnerData?.id}`,
            { ...values, age }
          );
        } else {
          response = await axios.post(`${baseUrl}users/register`, {
            ...values,
            age,
          });
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (response.status === 200) {
          const data = response.data.data;
          setFormValues(data);
          setToastVariant("success");
          if (!runnerData) {
            setToastMessage("Runner registration Successful!");
            setShowToast(true);
          } else {
            setShowToast(false);
            setShowModal(true);
            setEditedFields(getEditedFields(runnerData, values));
          }
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          setFormSubmitted(true);
          resetForm();

          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const errorData = await response.json();
          setToastVariant("danger");
          setToastMessage(
            runnerData
              ? "Failed runner details updation"
              : "Runner registration Failed"
          );
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          setShowToast(true);
          setFormSubmitted(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (error) {
        setToastVariant("danger");
        setToastMessage(
          runnerData
            ? "Error during runner details updation"
            : "Error during runner registration"
        );
        setShowToast(true);
        setFormSubmitted(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } finally {
        setIsLoading(false);
        setIsSubmittingForm(false);
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (event && formik.values.categoryName) {
      const selectedCategory = event.category.find(
        (cat) => cat.name === formik?.values?.categoryName
      );
      if (selectedCategory) {
        setCategoryMinimumAge(selectedCategory.minimumAge);
        setCategoryMaximumAge(selectedCategory.maximumAge);
        setGender(selectedCategory.gender);
        const distance = selectedCategory.distance;
        formik.setFieldValue("distance", distance);
      }
    }
  }, [event, formik.values.categoryName]);
  useEffect(() => {
    if (runnerData) {
      formik.setValues({
        ...formik.values,
        categoryName: runnerData.categoryName || "",
        firstName: runnerData.firstName || "",
        lastName: runnerData.lastName || "",
        dateOfBirth: runnerData.dateOfBirth
          ? moment(runnerData.dateOfBirth).format("YYYY-MM-DD")
          : "",
        gender: runnerData.gender || "",
        bloodGroup: runnerData.bloodGroup || "",
        nameOfTheBib: runnerData.nameOfTheBib || "",
        tShirtSize: runnerData.tShirtSize || "",
        medicalIssue: runnerData.medicalIssue || "",
        address: runnerData.address || "",
        state: runnerData.state || "",
        pincode: runnerData.pincode || "",
        email: runnerData.email || "",
        mobileNumber: runnerData.mobileNumber || "",
        contactName: runnerData.contactName || "",
        contactNumber: runnerData.contactNumber || "",
        runnerClub: runnerData.runnerClub || "",
        acceptedTerms: runnerData.acceptedTerms ?? false,
      });
    }
  }, [runnerData]);

  return (
    <>
      <div className="header-margin"></div>
      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      <div className="content">
        <CustomerInfo
          eventCategory={event}
          categoryMinimumAge={categoryMinimumAge}
          formik={formik}
          categoryNames={categoryNames}
        />

        <div className="row x-gap-20 y-gap-20 pt-20">
          <div className="col-auto">
            <button
              className="btn btn-primary h-60 px-24 -dark-1 bg-blue-1 text-white"
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            >
              {runnerData ? "Save" : "Register"}
            </button>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            textAlign: "center",
          }}
        >
          <div
            className={`alert alert-${toastVariant}`}
            role="alert"
            style={{ display: showToast ? "block" : "none" }}
          >
            {toastMessage}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edited Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {editedFields?.map((field, index) => (
              <div key={index} className="col-md-6">
                <p className="mt-0">
                  <strong>{field.field}:</strong>
                </p>
                <p className="mt-0">
                  {" "}
                  Old: {field.oldValue}, New: {field.newValue}
                </p>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;
