import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import { useParams } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import DashboardHeader from "./DashboardHeader";
import SidebarEvent from "./SidebarEvent";
import BibHistory from "./BibHistory";
import BibsTable from "./BibsTable";
import GenerateBib from "./GenerateBib";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import BlockingLoader from "./Common/Loader";

const BibGeneration = () => {
  const { slug } = useParams();
  const location = useLocation();
  const { eventId } = location.state || {};

  const [isOpen, setIsOpen] = useState(false);
  const [loadingGenerateBib, setLoadingGenerateBib] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };
  const [event, setEvent] = useState(null);
  const [firstTab, setFirstTab] = useState(false);
  const [secondTab, setSecondTab] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/history?eventId=${eventId}`
        );
        setEvent(response.data);
        console.log(event, "----event Details");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [eventId]);
  const handleTabSelect = async (key) => {
    if (key === "first") {
      setFirstTab(true);
    } else if (key === "second") {
      setSecondTab(true);
    }
  };

  return (
    <>
      <div className="header-margin"></div>
      <DashboardHeader handleToggle={handleToggle} />
      {loadingGenerateBib && <BlockingLoader />}
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      <div className="content">
        <BreadCrumb event={event} slug={slug} task={"Bib Generation"} />

        <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">
          <div style={{ display: "block", width: 900, padding: 10 }}>
            <Tabs defaultActiveKey="first" onSelect={handleTabSelect}>
              <Tab eventKey="first" title="History">
                <BibHistory
                  eventId={eventId}
                  firstTab={firstTab}
                  setFirstTab={setFirstTab}
                />
              </Tab>
              <Tab eventKey="second" title="Bibs">
                <BibsTable
                  eventId={eventId}
                  secondTab={secondTab}
                  setSecondTab={setSecondTab}
                  slug={slug}
                />
              </Tab>
              <Tab eventKey="third" title="Generate Bib">
                <GenerateBib slug={slug} eventId={eventId} setLoadingGenerateBib={setLoadingGenerateBib} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default BibGeneration;
