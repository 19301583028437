/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BreadCrumb from "./BreadCrumb";
import BootstrapTable from "react-bootstrap-table-next";
import Sidebar from "./Sidebar";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { getEvents } from "../api/events";
import BlockingLoader from "./Common/Loader";
import { getCertificates } from "../api/certificates";

const CertificatesList = () => {
  const { slug } = useParams();

  const [events, setEvents] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [eventsLoading, setEventsLoading] = useState(false);
  const [certificatesLoading, setCertificatesLoading] = useState("");

  const [eventsError, setEventsError] = useState("");
  const [certificatesError, setCertificatesError] = useState("");

  const fetchEvents = useCallback(async () => {
    try {
      setEventsError("");
      setEventsLoading(true);
      const response = await getEvents();
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      setEventsError(error.message);
    } finally {
      setEventsLoading(false);
    }
  }, []);

  const fetchCertificates = async () => {
    try {
      setCertificatesError("");
      setCertificatesLoading(true);
      const response = await getCertificates();
      setCertificates(response.data);
    } catch (error) {
      console.error("Error event by slug data:", error);
      setCertificatesError(error.message);
    } finally {
      setCertificatesLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchCertificates();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const handleDelete = async (certificateId) => {
    try {
      await axios.delete(
        `${baseUrl}events/delete-certificate?certificateId=${certificateId}`
      );
      await fetchCertificates();
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };
  function downloadCertificate(url) {
    // Create an anchor element
    var link = document.createElement("a");
    link.href = url;

    // Set download attribute to trigger download
    link.download = "certificate.pdf";

    // Append the anchor element to the body
    document.body.appendChild(link);

    // Trigger the click event to start download
    link.click();

    // Remove the anchor element
    document.body.removeChild(link);
  }

  const tableHeaders = [
    {
      dataField: "url",
      text: "Certificates",
      formatter: (cell, row) => {
        return (
          <div>
            Certificate{" "}
            <i
              className="fa fa-download text-secondary"
              style={{ cursor: "pointer" }}
              onClick={() => downloadCertificate(row.url)}
            ></i>
          </div>
        );
      },
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
        width: "auto",
      },
    },
    {
      dataField: "eventName",
      text: "Event",
      filter: selectFilter({
        options: events.reduce((acc, event) => {
          acc[event.eventName] = event.eventName;
          return acc;
        }, {}),
        placeholder: "Select Event",
        onFilter: (filterValue) => {},
        style: {
          width: "auto",
          marginLeft: "3px",
        },
        className: "form-select",
      }),
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex flex-row justify-content-center">
            {/* <Link
              className="btn text-secondary border-0"
              to="/upload-certificate" state={{row}}
            >
              <i className="fa fa-edit"></i>
            </Link> */}
            <button
              className="btn text-secondary border-0"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
  ];

  const rowStyle = (row, rowIndex) => {
    return {
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      width: "auto",
      textAlign: "center",
    };
  };

  return (
    <>
      <style>
        {`
          .sticky-header th {
            position: sticky;
            top: 0;
            background-color: #fff; 
            z-index: 1; 
          }
          .react-bootstrap-table td{
            padding-left: 1em;
          }
        `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      {(eventsLoading || certificatesLoading) && <BlockingLoader />}
      <div className="content">
        <BreadCrumb task={"Certificates"} />
        {/* <div className='d-flex flex-column flex-md-row justify-content-end gap-md-5 py-4'>
        <Link className="btn border btn-primary mb-3 mobile-width" to="/create-runnerclub">Create</Link>
        </div> */}

        {tableHeaders.length > 0 && (
          <div
            style={{ maxHeight: "450px", overflowY: "auto" }}
            className="table-responsive py-4"
          >
            <BootstrapTable
              keyField="id"
              data={certificates}
              columns={tableHeaders}
              rowStyle={rowStyle}
              filter={filterFactory()}
              headerClasses="sticky-header"
              pagination={paginationFactory({
                page: 1,
                sizePerPage: 20,
                totalSize: certificates.length,
                onPageChange: (page, sizePerPage) => {
                  setCurrentPage(page);
                },
                onSizePerPageChange: (page, sizePerPage) => {
                  setCurrentPage(1);
                  setPerPage(sizePerPage);
                },
              })}
              bootstrap4
              striped
              hover
              condensed
            />
          </div>
        )}
      </div>
      {eventsError && <p className="error-message">{eventsError}</p>}
      {certificatesError && (
        <p className="error-message">{certificatesError}</p>
      )}
    </>
  );
};

export default CertificatesList;
