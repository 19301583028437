import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import DashboardHeader from "./DashboardHeader";
import SidebarEvent from "./SidebarEvent";
import { baseUrl } from "../apiConfig";
import ResultTable from "./ResultTable";
import Sidebar from "./Sidebar";
import BlockingLoader from "./Common/Loader";

const CRM = () => {
  const { slug } = useParams();
  const [totalRunners, setTotalRunners] = useState(0);
  const [runners, setRunners] = useState([]);
  const [noRecords, setNoRecords] = useState(true);
  const { eventId, eventName } = useLocation().state || {};
  const [status, setStatus] = useState("COMPLETED");
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setError("");
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}users/eventIdAndOrderStatus?eventId=${eventId}&status=${status}`
      );
      if (response?.data?.length) {
        response?.data?.forEach((obj) => {
          const dateOfBirth = new Date(obj.dateOfBirth);
          obj.dateOfBirth = dateOfBirth.toISOString().split("T")[0];
        });
        setRunners(response.data.sort((a, b) => b.createdBy - a.createdBy));
        setTotalRunners(response.data.length);
      }
      setNoRecords(response.data.length === 0);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <style>{`
    body {
      overflow-x: hidden;
    }
    .sticky-header th {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }
    @media (max-width: 767px) {
      .mobile-width {
        width: 50%;
        margin: 0 auto;
      }
    }
  `}</style>
      <div className="header-margin"></div>
      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      <div className="content">
        <ResultTable
          slug={slug}
          eventId={eventId}
          eventName={eventName}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          status={status}
          setStatus={setStatus}
          runners={runners}
          noRecords={noRecords}
          setRunners={setRunners}
          setTotalRunners={setTotalRunners}
          setNoRecords={setNoRecords}
          isDistributedQr={true}
          isWhatsUp={true}
          isOrderStatus={true}
          isExportCsv={true}
          loading={loading}
        />
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
};

export default CRM;
