import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import SidebarEvent from './SidebarEvent';
import DashboardHeader from './DashboardHeader';
import axios from "axios";
import { baseUrl } from "../apiConfig";
import { useFormik } from 'formik';
import * as Yup from "yup";
import ToastComponent from './Common/Toast';
import Sidebar from './Sidebar';

const UploadWatermark = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
    const location = useLocation()
    const slug = new URLSearchParams(location.search).get("slug");
    const [event, setEvent] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}events/get-eventbyslug?slug=${slug}`);
          setEvent(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [slug]);

  const [isOpen, setIsOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
    file: '',
  },
  
  
  validationSchema: Yup.object().shape({
    file: Yup.string()
    .required('File is required'),
  }),
  onSubmit: async (values) => {
    const formData = new FormData();
    formData.append("file", values.file); 
    formData.append("eventId", values.eventId); 
    
    try {
      await axios.post(`${baseUrl}events/upload-watermark?event_slug=${slug}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
        setToastMessage(`Uploaded watermark to s3 successfully!`);  
      setShowToast(true);
      setToastVariant('success');
      setTimeout(() => {
        navigate(`/event/${slug}`);
      }, 1000);
  
    } catch (error) {
      setShowToast(true);
      setToastMessage("Error during file upload");
      setToastVariant('danger');
    }
  }
  });
  useEffect(() => {
    const eventId = event ? event?.id : null;
      formik.setFieldValue("eventId", eventId);
  }, [event]);

useEffect(()=>{
  if(showToast){
window.scrollTo({top: 0, behavior:"smooth"})
  }
}, [showToast])

const handleToggle = () => {
  setIsOpen(!isOpen);
  console.log("clicked")
};

  return (
    <>
      <div className='header-margin'></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />

      <div className="content">

        <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
          <form
        className="col-xl-7 col-lg-8 mt-30 mx-auto absolute"
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <div className="row">
          <div className="col-md-6 mt-4">
            <label className="text-16 text-light-1 mb-2">Upload Watermark</label>
              <input
                type="file"
                className='form-control'
                id="file"
                name="file"
                 onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
                 onBlur={formik.handleBlur}
                required
              />
              {formik.touched.file && formik.errors.file ? (
                <div className="text-danger">{formik.errors.file}</div>
              ) : null}
  </div>
          </div>
          <div className="row mt-4 text-center">
          <div className="col-12">
            <button
              className="btn btn-primary"
              type="submit"
            >
Upload
            </button>
          </div>
        </div>
          </form>
          </div>
        </div>
<ToastComponent
      showToast={showToast}
      toastHeader="Upload File"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  )
}

export default UploadWatermark;