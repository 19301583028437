import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";

import { baseUrl } from "../../apiConfig";

const CouponsPerEvent = ({ slug, loading, setLoading }) => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}events/get-results`);
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const [coupons, setCoupons] = useState([]);
  const fetchCoupons = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}coupons/getcoupons`);
      setCoupons(
        response.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter((item) => item?.eventId === event?.id)
      )
    } catch (error) {
      console.error("Error fetching data:", error);
    }
   finally {
    setLoading(false);
   }
    
  };

  useEffect(() => {
    fetchCoupons();
  }, [event]);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const formatExpiresAt = (expiresAt) => {
    return moment(expiresAt).format("YYYY-MM-DD HH:mm:ss");
  };

  const tableHeaders = [
    {
      dataField: "couponCode",
      text: "Coupon Code",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "expiresAt",
      text: "Expires At",
      formatter: (cell, row) => formatExpiresAt(cell),
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "maxRunners",
      text: "Max Runners",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "remainingRunners",
      text: "Remaining Runners",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "discountAmount",
      text: "Discount Amount",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "discountPercentage",
      text: "Discount Percentage",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },

    {
      dataField: "eventName",
      text: "Event",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "isActive",
      text: "Is Active",
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
  ];

  const rowStyle = {
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    textAlign: "center",
  };

  return (
    <>
      <style>
        {`
          .table-container {
            width: 100%;
            overflow-x: auto;
          }

          .sticky-header th {
            position: sticky;
            top: 0;
            background-color: #fff; 
            z-index: 1; 
          }
        `}
      </style>

      <div className="mt-4">
      {/* {loading && 
        <div className="d-flex justify-content-center mt-3">
        <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      } */}
      {!loading && coupons.length === 0 && <h3 className="text-center p-3 text-dark-1">No data available</h3>}
        {tableHeaders.length > 0 && coupons.length > 0 && (
          <div
            style={{ maxHeight: "450px", overflowY: "auto" }}
            className="table-responsive"
          >
            <BootstrapTable
              keyField="id"
              data={coupons}
              columns={tableHeaders}
              rowStyle={rowStyle}
              headerClasses="sticky-header"
              pagination={paginationFactory({
                page: 1,
                sizePerPage: 20,
                totalSize: coupons.length,
                onPageChange: (page, sizePerPage) => {
                  setCurrentPage(page);
                },
                onSizePerPageChange: (page, sizePerPage) => {
                  setCurrentPage(1);
                  setPerPage(sizePerPage);
                },
              })}
              bootstrap4
              striped
              hover
              condensed
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CouponsPerEvent;
