import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SidebarEvent from './SidebarEvent';
import DashboardHeader from './DashboardHeader';
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BreadCrumb from './BreadCrumb';
import { useFormik } from 'formik';
import * as Yup from "yup";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ToastComponent from './Common/Toast';
import Sidebar from './Sidebar';
import BlockingLoader from './Common/Loader';

const UploadCertificate = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [loading, setLoading] = useState(false);
    const location = useLocation()
    const slug = new URLSearchParams(location.search).get("slug");
    const [event, setEvent] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}events/get-eventbyslug?slug=${slug}`);
          setEvent(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [slug]);

  const [isOpen, setIsOpen] = useState(false);
  const handleRaceChange = (event) => {
    formik.setFieldValue('race', event.target.value);
  };
  const formik = useFormik({
    initialValues: {
    file: '',
    race: "",
    namePosition: "",
    timePosition: "",
    bibNumberPosition: "",
    distancePosition: ""
  },
  
  
  validationSchema: Yup.object().shape({
    file: Yup.string()
    .required('File is required'),
    race: Yup.string().required('Race is required'),
    namePosition: Yup.string().required('Name Position is required'),
    timePosition: Yup.string().required('Time Position is required'),
  }),
  onSubmit: async (values) => {
    const formData = new FormData();
    formData.append("file", values.file); 
    formData.append("eventId", values.eventId); 
    formData.append("race", values.race); 
    formData.append("namePosition", values.namePosition);
    formData.append("timePosition", values.timePosition);
    formData.append("bibNumberPosition", values.bibNumberPosition)
    formData.append("distancePosition", values.distancePosition)
    
    try {
      setLoading(true);
      await axios.post(`${baseUrl}events/upload-certificate?event_slug=${slug}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
        setToastMessage(`Uploaded Certificate to s3 successfully!`);  
      setShowToast(true);
      setToastVariant('success');
      setTimeout(() => {
        navigate(`/event/${slug}`);
      }, 1000);
  
    } catch (error) {
      setShowToast(true);
      setToastMessage("Error during file upload");
      setToastVariant('danger');
    }
    finally {
      setLoading(false);
    }
  }
  });
  useEffect(() => {
    const eventId = event ? event?.id : null;
      formik.setFieldValue("eventId", eventId);
  }, [event]);

useEffect(()=>{
  if(showToast){
window.scrollTo({top: 0, behavior:"smooth"})
  }
}, [showToast])

const handleToggle = () => {
  setIsOpen(!isOpen);
  console.log("clicked")
};

  return (
    <>
      <div className='header-margin'></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
{loading && <BlockingLoader/>}
      <div className="content">

        <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
          <form
        className="col-xl-7 col-lg-8 mt-30 mx-auto absolute"
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <div className="row">
        <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Race <span className="text-danger">*</span>
                    </label>
                    <select
                      id="race"
                      className="p-2 form-select text-dark-1"
                      name="race"
                      onChange={handleRaceChange}
                      onBlur={formik.handleBlur}
                     value={formik.values.race}
                    >
                      <option value="">Select Race</option>
                      {event?.race?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {formik.touched.race && formik.errors.race && (
                      <div className="text-danger">
                        {formik.errors.race}
                      </div>
                    )}
                  </div>
                </div>
          <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Upload Certificate <span className="text-danger">*</span></label>
              <input
                type="file"
                className='form-control text-dark-1'
                id="file"
                name="file"
                 onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
                 onBlur={formik.handleBlur}
                required
              />
              {formik.touched.file && formik.errors.file ? (
                <div className="text-danger">{formik.errors.file}</div>
              ) : null}
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Name Position <span className="text-danger">*</span></label>
              <input
                type="text"
                className='form-control'
                id="namePosition"
                name="namePosition"
                value={formik.values.namePosition}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.namePosition && formik.errors.namePosition ? (
                <div className="text-danger">{formik.errors.namePosition}</div>
              ) : null}
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Time Position <span className="text-danger">*</span></label>
              <input
                type="text"
                className='form-control'
                id="timePosition"
                name="timePosition"
                value={formik.values.timePosition}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                required
              />
              {formik.touched.timePosition && formik.errors.timePosition ? (
                <div className="text-danger">{formik.errors.timePosition}</div>
              ) : null}
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Bib Number Position</label>
              <input
                type="text"
                className='form-control'
                id="bibNumberPosition"
                name="bibNumberPosition"
                value={formik.values.bibNumberPosition}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
              />
              {formik.touched.bibNumberPosition && formik.errors.bibNumberPosition ? (
                <div className="text-danger">{formik.errors.bibNumberPosition}</div>
              ) : null}
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Distance Position</label>
              <input
                type="text"
                className='form-control'
                id="distancePosition"
                name="distancePosition"
                value={formik.values.distancePosition}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
              />
  </div>
          </div>
          <div className="row mt-4 text-center">
          <div className="col-12">
            <button
              className="btn btn-primary"
              type="submit"
            >
Upload
            </button>
          </div>
        </div>
          </form>
          </div>
        </div>
<ToastComponent
      showToast={showToast}
      toastHeader="Upload Certificate"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  )
}

export default UploadCertificate;