import React from "react";

const Modal = (props) => {
  const { children, modalStyles } = props;

  const onClickOutSide = () => {
    if (props.onClickOutSide) {
      props.onClickOutSide();
    }
  };
  return (
    <div className={`modal-container`} onClick={onClickOutSide}>
      <div className={`modal ${modalStyles}`}>{children}</div>
    </div>
  );
};

export default Modal;
