
import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory ,{ textFilter, selectFilter }from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { baseUrl } from "../apiConfig";

const BibsTable = ({ eventId, secondTab, setSecondTab ,slug}) => {
  const [events, setEvents] = useState();
  const [noRecords, setNoRecords] = useState(true);
  const [totalEvents, setTotalEvents] = useState();
  const [categories, setCategories] =useState([])
 
  const fetchData = async () => {
    const response = await axios.get(`${baseUrl}events/list?eventId=${eventId}`);
    if (response.status === 200) {
      setTotalEvents(response?.data?.count)
      setEvents(response?.data?.data)
      if ((response?.data?.data)?.length) {
        setNoRecords(false)
        setSecondTab(false)
      }
    }
  };
    const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}events/categories?slug=${slug}`);
      let result={};
      console.log(response?.data?.category,'Response')
      if(response?.data?.category){
      (response?.data?.category).forEach(item => {
            result[item.name] = item.name;
    });
  }
    setCategories(result);

    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, [eventId, secondTab]);


  const columns = [
    { dataField: 'categoryName', text: 'Category', sort: true ,  
    headerStyle: { width: '200px', height: '70px' },
    
    filter: selectFilter({ options: categories, placeholder: 'Select Category' })},
    {
      dataField: 'runnerId',
      text: 'RunnerId',
      sort: true,
    },
    {
      dataField: 'bibNumber',
      text: 'Bib Number',
      sort: true,
    },
    {
      dataField: 'chipCode',
      text: 'ChipCode',
      sort: true,
    },
  ];


  return (
    <>
      <style>
        {`
      body {
        overflow-x: hidden;
      }
      .sticky-header th {
        position: sticky;
        top: 0;
        background-color: #fff; 
        z-index: 1; 
      }
      @media (max-width: 767px) {
        .mobile-width {
          width: 50%;
          margin: 0 auto;
        }
      }
    `}
      </style>

      <div className="custom-min-height justify-content-center">
        <div className="">
          {noRecords ? <div className="text-center pt-45 font-weight-bold text-secondary">Bibs not generated</div> :
            <div className="chart_responsive">
              <div className="rounded-4 bg-white shadow-3 custom-min-height">
                <div className="container-fluid my-4">
                  <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">
                    {events.length > 0 && (
                      <div>
                        <BootstrapTable
                          keyField="id"
                          data={events}
                          columns={columns}
                          striped
                          hover
                          condensed
                          filter={filterFactory()}
                          headerClasses="sticky-header"
                          pagination={paginationFactory()}
                          rowClasses="my-5"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

    </>
  );
};

export default BibsTable;
