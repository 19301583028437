import { useState, useCallback, useMemo } from "react";
//import DatePicker from 'react-datepicker';
import DatePicker from "react-multi-date-picker";
import { Modal, } from 'react-bootstrap';
import {format} from "date-fns";

const TShirtSizeModal = ({ show, handleClose, eventCategory }) => {
  return (
    <>
    <style>
      {
        `
        @media (max-width: 576px) {
  .nowrap-table th,
  .nowrap-table td {
    white-space: nowrap;
  }
}
        `
      }
    </style>
  <Modal show={show} onHide={handleClose} size="xl">
  <Modal.Header closeButton className='bg-blue-2'>
    <Modal.Title>Size Chart</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div className="table-responsive">
<table className="table table-striped border mx-auto nowrap-table">
<thead>
  <tr>
    <th colSpan={5} className='py-2 text-center'>REGULAR SIZES</th>
  </tr>
<tr>
<th scope="col" className='py-1'>Brand Size</th>
<th scope="col" className='py-1'>Standard Size</th>
<th scope="col" className='py-1'>Chest (in)</th>
<th scope="col" className='py-1'>Shoulder (in)</th>
<th scope="col" className='py-1'>Length (in)</th>
</tr>
</thead>
<tbody>
<tr>
<td className='py-1'>XS</td>
<td className='py-1'>XS</td>
<td className='py-1'>36</td>
<td className='py-1'>16.3</td>
<td className='py-1'>25.5</td>
</tr>
<tr>
<td className='py-1'>S</td>
<td className='py-1'>S</td>
<td className='py-1'>38</td>
<td className='py-1'>16</td>
<td className='py-1'>27.8</td>
</tr>
<tr>
<td className='py-1'>M</td>
<td className='py-1'>M</td>
<td className='py-1'>40</td>
<td className='py-1'>16.8	</td>
<td className='py-1'>28.2</td>
</tr>
<tr>
<td className='py-1'>L</td>
<td className='py-1'>L</td>
<td className='py-1'>42	</td>
<td className='py-1'>17.5</td>
<td className='py-1'>	28.8</td>
</tr>
<tr>
<td className='py-1'>XL</td>
<td className='py-1'>XL</td>
<td className='py-1'>45	</td>
<td className='py-1'>18.2	</td>
<td className='py-1'>29.2</td>
</tr>
<tr>
<td className='py-1'>XXL</td>
<td className='py-1'>XXL</td>
<td className='py-1'>47.5</td>
<td className='py-1'>19</td>
<td className='py-1'>29.8</td>
</tr>
<tr>
<td className='py-1'>3XL</td>
<td className='py-1'>3XL</td>
<td className='py-1'>50</td>
<td className='py-1'>	19.8	</td>
<td className='py-1'>30.2</td>
</tr>
{ eventCategory?.slug !== 'salem-runners-marathon-2024' && (
  <>
<tr>
    <th colSpan={5} className='py-2 text-center'>KIDS SIZES</th>
  </tr>
  <tr>
<td className='py-1'>2-4 Yrs 24 inches</td>
<td className='py-1'>2-4 Yrs 24 inches</td>
<td className='py-1'></td>
<td className='py-1'></td>
<td className='py-1'></td>
</tr>
<tr>
<td className='py-1'>4-5 Yrs 26 inches</td>
<td className='py-1'>4-5 Yrs 26 inches</td>
<td className='py-1'></td>
<td className='py-1'></td>
<td className='py-1'></td>
</tr>
<tr>
<td className='py-1'>5-7 Yrs 28 inches</td>
<td className='py-1'>5-7 Yrs 28 inches</td>
<td className='py-1'></td>
<td className='py-1'></td>
<td className='py-1'></td>
</tr>
<tr>
<td className='py-1'>7-8 Yrs 30 inches</td>
<td className='py-1'>7-8 Yrs 30 inches</td>
<td className='py-1'></td>
<td className='py-1'></td>
<td className='py-1'></td>
</tr>
<tr>
<td className='py-1'>8-10 Yrs 32 inches</td>
<td className='py-1'>8-10 Yrs 32 inches</td>
<td className='py-1'></td>
<td className='py-1'></td>
<td className='py-1'></td>
</tr>
</>)
}
</tbody>
</table>
</div>
  </Modal.Body>

</Modal>
</>
)}


const CustomerInfo = ({formik, eventCategory, categoryNames, categoryMinimumAge}) => {
  console.log(formik, "formik")
  const [showTermsPopover, setShowTermsPopover] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hoverDivStyles = {
    color: isHovered ? '#dc3545' : '#007bff',
    textDecoration: isHovered ? 'underline' : '',
  };

  const calculateAge = useMemo(() => {
    return (dateOfBirth) => {
      const birthDate = new Date(dateOfBirth);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();

      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        return age - 1;
      }
  
      return age;
    };
  }, []);
  


  const [age, setAge] = useState(calculateAge(formik.values.dateOfBirth));

  const handleDateChange = useCallback(
    (date) => {
      formik.setFieldTouched('dateOfBirth', true);
      formik.setFieldValue("dateOfBirth", date instanceof Date ? date : new Date(date));
      
      const newAge = calculateAge(date);      
      setAge(newAge);
    },
    [formik.setFieldValue, calculateAge, categoryMinimumAge, formik.setFieldError]
  );

  const IndianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];
  const RunnerClub = [
    "None",
"500 MILER",
"AERO RUNNERS",
"ANNUR IMA",
"ANNUR RUNNERS",
"ANT RUNNERS",
"ASICS Running Club Chennai",
"BADUGA RUNNERS",
"BAILWAN RUNNERS",
"BLUE MOUNTAIN",
"BOISTEROUS RUNNERS",
"Chennai Runners - Ambattur Arimass",
"Chennai Runners - Bessie Flyers",
 "Chennai Runners - Chromepet Cheetahs",
"Chennai Runners - Coastal Runners",
"Chennai Runners - Forest Gumps",
"Chennai Runners - Kilpauk Striders",
"Chennai Runners - Marina Minnals",
"Chennai Runners - Mogappair Fitness Circle",
"Chennai Runners - Nungambakkam Nitros",
"Chennai Runners - OMR Trailblazers",
"Chennai Runners - Pettai Rappers",
"Chennai Runners - Pillar Pacers",
"Chennai Runners - Porur Racers",
"Chennai Runners - T.Nagar Trendsetters",
"Chennai Runners - Tambaram Thunderbolts",
"Chennai Runners - Tower Twisters",
"Chennai Runners - Vibrant Velachery",
"Chennai Trekking Club",
"CODESSIA RUNNERS ",
"Cool Runners",
"Dream Runners - Airport Dreamers",
"Dream Runners - Anna Nagar Dreamers ",
"Dream Runners - Avadi Dreamers",
"Dream Runners - Bessie Dreamers",
"Dream Runners - Boat Club Dreamers",
"Dream Runners - Marina Dreamers",
"Dream Runners - Medavakkam Dreamers",
"Dream Runners - MRC Dreamers",
"Dream Runners - OMR Dreamers",
"Dream Runners - RUN T-NAGAR RUN",
"Dream Runners - Velachery Dreamers",
"Dream Runners - VMR Dreamers",
"DYNAMIC SPORTS ACADEMY",
"ERODE RUNNERS",
"FORT RUNNERS PALAKAD",
"FREE RUNNERS",
"GENESIS SPORTS FOUNDATION",
"GIB Fitness Club",
"HAPPY FEET RUNNERS",
"Hyderabad Runners",
"ILAMPILLAI RUNNERS",
"INCG",
"ITI PACEMAKERS",
"KALANGAL RUNNERS",
"KANGEYAM RUNNERS",
"KaysFIT Academy",
"KOVAIPUDUR RUNNERS",
"Mongrels",
"MUTHU MARATHON",
"Nellai Runners",
"NOMADIC RUNNERS",
"ORA RUNNERS",
"Others",
"PACEMAKER",
"PERUNDURAI RUNNERS",
"PHOENIX RUNNER - COIMBATORE",
"POLLACHI RUNNERS",
"ROBERT BOSCH VOC",
"SALEM RUNNERS",
"SARAVANAMPATTI CHAPTER",
"SBC RIDERS",
"SBC Striders",
"SINGAI SINGAMS, COIMBATORE",
"Sivagiri Runners",
"TAMILNADU ROCKET RUNNERS",
"Teenduruns",
"TIRUPUR PHOENIX",
"Tirupur Runners",
"U 2 RUNNERS",
"UDALINAI URUTHI SEI",
"UNIS Running",
"Valasai Venghais",
"VMR Dreamers",
"WARRIOR RUNNERS",
"WCCG",
"YOU TOO CAN RUN",
"Others"
  ]
  const dateString = eventCategory?.date || "";
  let formattedDate = "";
if (dateString) {
  const date = new Date(dateString);

  if (!isNaN(date.getTime())) {
    formattedDate = format(date, "MMMM dd, yyyy - EEEE");
    console.log(formattedDate, "formatted");
  } else {
    console.log("invalid date string");
  }
} else {
  console.error("Date string is undefined");
}
  return (
    <>
    <div className="container-fluid p-3">
    {eventCategory?.tag === "Closed" &&
      <div className="text-center text-danger bg-light p-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M0 0h24v24H0z"></path><path fill="currentColor" d="M12 1.67c.955 0 1.845.467 2.39 1.247l.105.16l8.114 13.548a2.914 2.914 0 0 1-2.307 4.363l-.195.008H3.882a2.914 2.914 0 0 1-2.582-4.2l.099-.185l8.11-13.538A2.914 2.914 0 0 1 12 1.67M12.01 15l-.127.007a1 1 0 0 0 0 1.986L12 17l.127-.007a1 1 0 0 0 0-1.986zM12 8a1 1 0 0 0-.993.883L11 9v4l.007.117a1 1 0 0 0 1.986 0L13 13V9l-.007-.117A1 1 0 0 0 12 8"></path></g></svg>
        <h5>Registrations for this event is closed. See you all on {formattedDate}</h5>
      </div>
}
      <form
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >

        <h5 className="text-dark-1">Please Enter Runner Details:</h5>
        <div className="row x-gap-40 y-gap-20">
        <div className="col-md-6">
        <div className="border p-4">
        <p className="fw-bold text-dark-1">PERSONAL DATA</p>
          <div className="form-group form-input row m-2">
          <label className="text-16 text-dark-1 fw-bold col-sm-4">Categories <span className="text-danger">*</span></label>
            <div className='col-sm-8'>
              <select
                id="catgeories"
                className="p-2 form-select text-dark-1"
                name="categoryName"
                
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.categoryName || ""}
                disabled={eventCategory?.tag === "Closed"}
              >
               <option value="">Select Category</option>
                {eventCategory?.category?.map((category, index) => (
                  <option key={index} value={category.name}>
                    {category.name} - Rs. {category.amount}
                  </option>
                ))}
              </select>
              {formik.touched.categoryName && formik.errors.categoryName && (
                <div className="text-danger">{formik.errors.categoryName}</div>
              )}
              </div>
              
            </div>
            <div className="form-group form-input row m-2">
            <label className="text-16 text-dark-1 fw-bold col-sm-4">First Name <span className='text-danger'>*</span></label>
            <div className="col-sm-8">
              <input
                type="text"
                id="firstname"
                name="firstName"
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.firstName || ""}
                disabled={!formik.values.categoryName} className='form-control'/>
  {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-danger">{formik.errors.firstName}</div>
            )}
            </div>
          
            </div>

            <div className='form-group form-input row m-2'>
              
                <label className="text-16 text-dark-1 fw-bold col-sm-4">Last Name <span className='text-danger'>*</span></label>           
                <div className="col-sm-8">
                  <input
                    type="text"
                    id="lastname"
                    name="lastName"
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName || ""}
                    onChange={(e) => formik.handleChange(e)}
                    disabled={!formik.values.categoryName} className='form-control'
                  />
{formik.touched.lastName && formik.errors.lastName && (
                  <div className="text-danger">{formik.errors.lastName}</div>
                )}
                  </div>
            </div>

            <div className='form-group form-input row m-2'>
              <label className="text-16 text-dark-1 fw-bold col-sm-4">Date of Birth <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
              <div className="form-control" style={{backgroundColor: !formik.values.categoryName ? "#e9ecef" : "",}}>
            <DatePicker
                inputClass=""
                containerClassName=""
                value={
                  formik.values.dateOfBirth
                    ? new Date(formik.values.dateOfBirth)
                    : null
                }
                onChange={handleDateChange}   
                disabled={!formik.values.categoryName}
                id="dateOfBirth"
                name="dateOfBirth"
                format="DD/MM/YYYY"
                className="datepicker-no-outline"
                style={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  width: "100%",
                }}
              />
              </div> 
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                <div className="text-danger">{formik.errors.dateOfBirth}</div>
              )}              
              </div>
            </div>
            <div className='form-group form-input row m-2'>
              <label className="text-16 text-dark-1 fw-bold col-sm-4">Gender <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
                <select className='form-select text-dark-1' 
                name="gender" 
                id="gender" 
                disabled={!formik.values.categoryName}
                value={formik.values.gender || ""}
                 onChange={(e) => {formik.handleChange(e)
                //  formik.setFieldTouched('gender', true);
                }}
                  onBlur={formik.handleBlur} >
                  <option value="">Please Select</option>
                  <option value="Male">Male</option>
      <option value="Female">Female</option>
                </select>                                
                {formik.errors.gender && (
                  <div className="text-danger">{formik.errors.gender}</div>
                )}
              </div>
            </div>

            <div className='form-group form-input row m-2'>
              <label className="text-16 text-dark-1 fw-bold col-sm-4">Blood Group <span className='text-danger'>*</span></label>
              <div className="col-sm-8">            
              <select
                id="bloodgroup"
                className="p-2 form-select text-dark-1"
                name="bloodGroup"
                style={{
                  width: "100%",
                  padding: "0.4em",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgray",
                  //color: "gray",
                }}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.bloodGroup || ""}
                disabled={!formik.values.categoryName}
              >
                <option value="">Please Select</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </select>
              {formik.touched.bloodGroup && formik.errors.bloodGroup && (
              <div className="text-danger">{formik.errors.bloodGroup}</div>
            )}
            </div>
            
            </div>

            <div className='form-group form-input row m-2'>
              <label className="text-16 text-dark-1 fw-bold col-sm-4"> Name On the bib <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
              <input type="text" id="bib" className='form-control' name="nameOfTheBib" maxLength={16} onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameOfTheBib || ""}
                  disabled={!formik.values.categoryName}
                />
                {formik.touched.nameOfTheBib && formik.errors.nameOfTheBib && (
                  <div className="text-danger">{formik.errors.nameOfTheBib}</div>
                )}
              </div>
            </div>

            <div className='form-group form-input row m-2'>
              <label className="text-16 text-dark-1 fw-bold col-sm-4">T Shirt size <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
              <select id="tshirtsize" className="p-2 form-select text-dark-1" name="tShirtSize" onChange={(e) => formik.handleChange(e)} onBlur={formik.handleBlur}
                style={{
                  width: "100%",
                  padding: "0.4em",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgray",
                  //color: "gray",
                }}  value={formik.values.tShirtSize || ""} disabled={!formik.values.categoryName}>
                <option value="">Please Select</option>
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
                <option value="3XL">3XL</option>
                {eventCategory?.slug !== 'salem-runners-marathon-2024' && (<optgroup label="Kids">
                <option disabled>───────────</option>
                <option value="2-4 Yrs 24 inches">2-4 Yrs 24 inches</option>
                <option value="4-5 Yrs 26 inches">4-5 Yrs 26 inches</option>
                <option value="5-7 Yrs 28 inches">5-7 Yrs 28 inches</option>
                <option value="7-8 Yrs 30 inches">7-8 Yrs 30 inches</option>
                <option value="8-10 Yrs 32 inches">8-10 Yrs 32 inches</option>
              </optgroup>)}
              </select>
              {formik.touched.tShirtSize && formik.errors.tShirtSize && (
                  <div className="text-danger">{formik.errors.tShirtSize}</div>
                )}
              <div variant="primary" onClick={handleShow} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`pointer`} style={hoverDivStyles}>
                  Size Chart <i className="fa fa-angle-down"></i>
              </div>
              <TShirtSizeModal show={show} handleClose={handleClose} eventCategory={eventCategory}/>
            </div>
          </div>
          
          {eventCategory?.slug !== 'salem-runners-marathon-2024' && (
          <div className='form-group form-input row m-2'>
            <label className="text-16 text-dark-1 fw-bold col-sm-4">Medical Issues</label>
            <div className='col-sm-8'>
               <input type="text" id="medicalissue" className='form-control p-2 custom-placeholder' placeholder="Please enter medical issues if any" name="medicalIssue" onChange={(e) => formik.handleChange(e)} onBlur={formik.handleBlur} value={formik.values.medicalIssue || ""} disabled={!formik.values.categoryName}/>
               {formik.touched.medicalIssue && formik.errors.medicalIssue && (
                <div className="text-danger">{formik.errors.medicalIssue}</div>
              )}
            </div>
          </div>
            )}
            {eventCategory?.slug === 'salem-runners-marathon-2024' && (
              <>
            <div className='form-group form-input row m-2'>
            <label className="text-16 text-light-1 col-sm-8">
            Do you suffer from ailments with blood pressure or cardiac or circulatory disorders?
            </label>
            <div className='col-sm-4'>
              <select id="chronicIssues" disabled={!formik.values.categoryName} name="disorders" className="disorders form-select text-dark-1" onChange={(e) => formik.handleChange(e)} onBlur={formik.handleBlur}>
                    <option selected>YES</option>
                    <option>NO</option>
              </select>
            </div>
          </div>

          <div className='form-group form-input row m-2'>
            <label className="text-16 text-light-1 col-sm-8">
              Do you have any chronic disease?
            </label>
            <div className='col-sm-4 col align-self-end'>
              <select id="chronicIssues" disabled={!formik.values.categoryName} name="chronicIssues" className="disorders form-select form-control text-dark-1" onChange={(e) => formik.handleChange(e)} onBlur={formik.handleBlur}>
                    <option selected>YES</option>
                    <option >NO</option>
              </select>
            </div>
          </div>
          </>
          )}
          <div className='form-group form-input row m-2'>
            <label className="text-16 text-dark-1 fw-bold col-sm-4">Address <span className='text-danger'>*</span></label>
            <div className='col-sm-8'>
              <textarea type="textarea" id="address" className='form-control custom-placeholder' name="address" placeholder='House, apartment, street, city'
              onChange={(e) => formik.handleChange(e)} onBlur={formik.handleBlur} value={formik.values.address || ""} 
              disabled={!formik.values.categoryName}/>
               {formik.touched.address && formik.errors.address && (
                <div className="text-danger">{formik.errors.address}</div>
              )}
            </div>
          </div>

          <div className='form-group form-input row m-2'>
            <label className="text-16 text-dark-1 fw-bold col-sm-4">State <span className='text-danger'>*</span></label>
            <div className='col-sm-8'>
                <select
                id="state"
                className="p-2 form-select text-dark-1"
                name="state"
                style={{
                  width: "100%",
                  padding: "0.4em",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgray",
                  //color: "gray",
                }}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.state || ""}
                disabled={!formik.values.categoryName}>
                <option value="">Please Select</option>
                {IndianStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {formik.touched.state && formik.errors.state && (
                <div className="text-danger">{formik.errors.state}</div>
              )}
            </div>            
            </div>
            
            <div className='form-group form-input row m-2'>
              <label className="text-16 text-dark-1 fw-bold col-sm-4">Pincode <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
              <input type="text" id="pincode" className='form-control' name="pincode"
                  onChange={(e) => formik.handleChange(e)} onBlur={formik.handleBlur} value={formik.values.pincode || ""} disabled={!formik.values.categoryName}
                />                
              {formik.touched.pincode && formik.errors.pincode && (
                <div className="text-danger">{formik.errors.pincode}</div>
              )}
              </div>
            </div>


        </div>
            
  </div>

<div className="col-md-6">
  <div className="border p-4">
<p className="fw-bold text-dark-1">CONTACT</p>
<p className="text-16 text-dark-1">Email and Phone number on which you will receive the communication regarding the race</p>
          <div className="form-input form-group row m-2">
          <label className="text-16 text-dark-1 fw-bold col-sm-4">Email <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
              <input
                type="email"
                id="email"
                className='form-control'
                name="email"
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.email || ""}
                disabled={!formik.values.categoryName}
              />

              

            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">{formik.errors.email}</div>
            )}
            </div>
          </div>

            <div className="form-input form-group row m-2">
            <label className="text-16 text-dark-1 fw-bold col-sm-4">Phone Number <span className='text-danger'>*</span></label>
              <div className='col-sm-8'>
              <input
                type="text" 
                className='form-control' 
                id="mobilenumber"
                name="mobileNumber"
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.mobileNumber || ""}
                disabled={!formik.values.categoryName}
              />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div className="text-danger">{formik.errors.mobileNumber}</div>
            )}
            </div>
          </div>


<p className="fw-bold mt-3 text-dark-1">EMERGENCY CONTACT</p>
          <div className="form-input form-group row m-2">
            
            <label className="text-16 text-dark-1 fw-bold col-sm-4">
                Name <span className='text-danger'>*</span>
              </label>
              <div className="col-sm-8">
              <input
                type="text"
                id="emergencycontactname"
                name="contactName" 
                className='form-control' 
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.contactName || ""}
                disabled={!formik.values.categoryName}
              />
{formik.touched.contactName && formik.errors.contactName && (
              <div className="text-danger">{formik.errors.contactName}</div>
            )}
            </div>
          </div>
          <div className="form-input form-group row m-2">
            
            <label className="text-16 text-dark-1 fw-bold col-sm-4">
                Number <span className='text-danger'>*</span>
              </label>
              <div className="form-input col-sm-8">
              <input
                type="text"
                id="emergencycontactnumber"
                name="contactNumber" 
                className='form-control'
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.contactNumber || ""}
                disabled={!formik.values.categoryName}
              />
              {formik.touched.contactNumber && formik.errors.contactNumber && (
              <div className="text-danger">{formik.errors.contactNumber}</div>
            )}
            </div>

          </div>
          <p className="fw-bold mt-3 text-dark-1">Enter Coupon code if exists</p>
           <div className="form-input form-group row m-2">
            
            <label className="text-16 text-dark-1 fw-bold col-sm-4">
                Code
              </label>
              <div className="form-input col-sm-8">
              <input
                type="text"
                id="couponCode"
                name="couponCode" 
                className='form-control'
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.couponCode || ""}
                disabled={!formik.values.categoryName}
              />
              {formik.touched.couponCode && formik.errors.couponCode && (
                <div className="text-danger font-weight-bold">
                Please enter a valid coupon code
                </div>
              )}       
            </div>            
          </div> 
          <div className='form-group form-input row m-2'>
            <label className="text-16 text-dark-1 fw-bold col-sm-4">Runner Club</label>
            <div className='col-sm-8'>
                <select
                id="runnerClub"
                className="p-2 form-select text-dark-1"
                name="runnerClub"
                style={{
                  width: "100%",
                  padding: "0.4em",
                  borderRadius: "7px",
                  outline: "none",
                  border: "1px solid lightgray",
                  //color: "gray",
                }}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.runnerClub || ""}
                disabled={!formik.values.categoryName}>
                <option value="">Please Select</option>
                {RunnerClub.map((runner, index) => (
                  <option key={index} value={runner.toUpperCase()}>
                    {runner.toUpperCase()}
                  </option>
                ))}
              </select>
              {formik.touched.runnerClub && formik.errors.runnerClub && (
                <div className="text-danger">{formik.errors.runnerClub}</div>
              )}
            </div>            
            </div>
            <div className="form-input form-group row m-2">
            
            <label className="text-16 text-dark-1 fw-bold col-sm-4">
                Offline Transaction
              </label>
              <div className="form-input col-sm-8">
              <input
                type="text"
                id="offlineTransactionId"
                name="offlineTransactionId" 
                className='form-control'
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.offlineTransactionId || ""}
                disabled={!formik.values.categoryName}
              />
              {formik.touched.offlineTransactionId && formik.errors.offlineTransactionId && (
                <div className="text-danger font-weight-bold">
                <div className="text-danger">{formik.errors.offlineTransactionId}</div>
                </div>
              )}       
            </div>            
          </div> 
            {/* <div className="form-input form-group row m-2">
            
            <label className="text-16 text-dark-1 fw-bold col-sm-4">
            Offline Transaction
              </label>
              <div className="form-input col-sm-8">
              <input
                type="text"
                id="offlineTransactionId"
                name="offlineTransactionId" 
                className='form-control'
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values.offlineTransactionId || ""}
                disabled={!formik.values.categoryName}
                required
              />
              {formik.touched.offlineTransactionId && formik.errors.offlineTransactionId && (
                <div className="text-danger font-weight-bold">
               <div className="text-danger">{formik.errors.offlineTransactionId}</div>
                </div>
              )}       
            </div>            
          </div>  */}

</div>
  </div>


</div>
<div className='mt-3 text-dark-1'>
<span className="text-danger">*</span> Indicates mandatory fields
</div>
<div className="row x-gap-20 y-gap-20 pt-10">
<div className="col-md-6">
<div class="form-check">
  <input className="form-check-input"
   type="checkbox"
                  name="acceptedTerms"
                  checked={formik.values.acceptedTerms}
                  onChange={(e) => formik.setFieldValue("acceptedTerms", e.target.checked)}
                  onBlur={formik.handleBlur}
                  disabled={!formik.values.categoryName}
                  required/>
  <label className="text-dark-1" for="flexCheckDefault">
  I have read and agree to the {" "}
          <span
          style={{cursor:"pointer", textDecoration:"underline"}}
            className="terms-link"
            onClick={() => setShowTermsPopover(true)}
          >
            terms and conditions
          </span>
  </label>
</div>
            {formik.touched.acceptedTerms && formik.errors.acceptedTerms && (
              <div className="text-danger">{formik.errors.acceptedTerms}</div>
            )}
       
</div>
</div>
<TermsAndConditionsPopover
        showPopover={showTermsPopover}
        onClose={() => setShowTermsPopover(false)}
      />

      </form>
    </div>
  </>
  );
};

export default CustomerInfo;

const TermsAndConditionsPopover = ({ showPopover, onClose }) => {
  return (
    showPopover && (
      <div className="popover">
        <div className="popover-content">
          <span className="close" onClick={onClose}>&times;</span>
          <div className="tabs__content js-tabs-content" data-aos="fade">
              <h5 className="text-14 fw-600">
                NovaRace Event Terms and Conditions:
              </h5>
              <p className="text-15 text-dark-1">
                Welcome to NovaRace! Before participating in any of our events,
                please read and agree to the following terms and conditions:
              </p>
              <h6 className="text-16 fw-500 mt-15">
                Organizer Responsibility:
              </h6>
              <div>
                <ul className="list-disc text-15 text-dark-1">
                  <li>
                    NovaRace acts solely as a platform to facilitate event
                    registration and promotion. We do not assume responsibility
                    for the organization, conduct, or safety of any events
                    listed on our platform.
                  </li>
                  <li>
                    Event organizers are solely responsible for the planning,
                    execution, and safety measures of their respective events.
                  </li>
                </ul>

                <h6 className="text-16 fw-500 mt-15">
                  Participant Responsibilities:
                </h6>
                <ul className="list-disc text-15 text-dark-1">
                  <li>
                    By registering for an event on NovaRace, participants
                    acknowledge and accept that they are solely responsible for
                    their own safety, health, and actions during the event.
                  </li>
                  <li>
                    Participants must comply with the rules, regulations, and
                    guidelines set by the event organizer.
                  </li>
                </ul>

                <h6 className="text-16 fw-500 mt-15">Liability Waiver:</h6>
                <ul className="list-disc text-15 text-dark-1">
                  <li>
                    Participants understand and agree that NovaRace is not
                    liable for any injuries, damages, losses, or expenses that
                    may occur during the event.
                  </li>
                  <li>
                    Participants waive any claims against NovaRace and its
                    affiliates in connection with their participation in the
                    event.
                  </li>
                </ul>

                <h6 className="text-16 fw-500 mt-15">
                  Event Changes or Cancellation:
                </h6>
                <ul className="list-disc text-15 text-dark-1">
                  <li>
                    NovaRace reserves the right to modify event details,
                    including dates, times, and locations, at its discretion.
                  </li>
                  <li>
                    In the event of cancellation, NovaRace is not responsible
                    for any costs incurred by participants, such as travel or
                    accommodation expenses.
                  </li>
                </ul>
                <h6 className="text-16 fw-500 mt-15">
                  Registration and Payment:
                </h6>
                <ul className="list-disc text-15 text-dark-1">
                  <li>
                    Event registration is complete only upon receipt of payment,
                    and refunds are subject to the event organizer&apos;s
                    policies.
                  </li>
                  <li>
                    NovaRace does not store or handle payment information
                    directly. All transactions are securely processed through
                    third-party payment gateways.
                  </li>
                </ul>
                <h6 className="text-16 fw-500 mt-15">Personal Information:</h6>
                <ul className="list-disc text-15 text-dark-1">
                  <li>
                    NovaRace collects personal information for the sole purpose
                    of event registration and communication.
                  </li>
                  <li>
                    Participants&apos; personal information will not be shared,
                    sold, or used for any other purposes without explicit
                    consent.
                  </li>
                </ul>
              </div>
              <p className="text-15 text-dark-1 mt-10">
                By registering for a NovaRace event, participants confirm that
                they have read, understood, and agreed to these terms and
                conditions. NovaRace reserves the right to update or modify
                these terms as needed. Participants are encouraged to review the
                terms regularly for any changes.
              </p>
              <p className="text-15 text-dark-1 mt-10">
                If you have any questions or concerns, please contact us at {" "}
                <a href="mailto:support@novarace.in">support@novarace.in</a>.
                <br />
                Thank you for being part of the NovaRace community!
              </p>
            </div>
        </div>
      </div>
    )
  );
};