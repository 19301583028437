import httpClient from "./httpClient";

export const getRunnersClub = () => {
  return httpClient({
    method: "GET",
    url: "/users/getAllRunnerClub",
  });
};

export const deleteRunnersClub = (params) => {
  return httpClient({
    method: "DELETE",
    url: "/users/runnerclub-delete",
    params,
  });
};
