import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";

const Table = (props) => {
  const {
    keyField,
    data,
    columns,
    currentPage,
    perPage,
    setCurrentPage,
    setPerPage,
  } = props;

  const rowStyle = () => {
    return {
      verticalAlign: "middle",
      lineHeight: "0.1em",
      whiteSpace: "nowrap",
      textAlign: "center",
    };
  };

  return (
    <>
      <style>
        {`
          .sticky-header th {
            position: sticky;
            top: 0;
            background-color: #fff; 
            z-index: 1; 
          }
          .fixed-pagination {
            position: sticky;
            bottom: 0;
            background-color: #fff; 
            z-index: 1; 
            width: 100%;
          }
        `}
      </style>

      <>
        {columns.length > 0 && (
          <div
            style={{ maxHeight: "600px", overflowY: "auto" }}
            className="table-responsive"
          >
            <BootstrapTable
              keyField={keyField}
              data={data}
              columns={columns}
              rowStyle={rowStyle}
              filter={filterFactory()}
              headerClasses="sticky-header"
              pagination={paginationFactory({
                page: currentPage,
                sizePerPage: perPage,
                totalSize: data.length,
                onPageChange: (page, sizePerPage) => {
                  setCurrentPage(page);
                },
                onSizePerPageChange: (page, sizePerPage) => {
                  setCurrentPage(1);
                  setPerPage(sizePerPage);
                },
              })}
              classes="fixed-pagination"
              {...props}
              bootstrap4
              striped
              hover
              condensed
            />
          </div>
        )}
      </>
    </>
  );
};

export default Table;
