import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BreadCrumb from "./BreadCrumb";
import BootstrapTable from "react-bootstrap-table-next";
import Sidebar from "./Sidebar";
import parse from "html-react-parser";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import UploadStartList from "./UploadStartList";
import LiveResults from "./LiveResults";
import UploadResultsTab from "./UploadResultsTab";
import { getResults } from "../api/results";
import BlockingLoader from "./Common/Loader";
import DisplayResultsTab from "./DisplayResultsTab";
import AddFields from "./AddFields";

const Results = () => {
  const { slug } = useParams();
  const [firstTab, setFirstTab] = useState(false);
  const [secondTab, setSecondTab] = useState(false);
  const [thirdTab, setThirdTab] = useState(false);

  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState();
  const [eventName, setEventName] = useState();

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const response = await axios.get(`${baseUrl}events/get-results`);
    setEvents(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [enhancedResults, setEnhancedResultsWithEdits] = useState([]);
  const updateResults = (newResults) => {
    setEnhancedResultsWithEdits(newResults);
  };
  const [event, setEvent] = useState(null);
  const [description, setDescription] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // const response = await getResults({
        //   status: "RESULTSUPLOADED",
        //   page: 1,
        //   perPage: 8,
        // });
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );  
        // setResults(response.data);
        setEvent(response.data);
        setEventId(response.data.id);
        setEventName(response.data.eventName);
        setDescription(parse(response?.data?.aboutEvent));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [slug]);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    { dataField: "name", text: "Category" },
    { dataField: "amount", text: "Registration Amount" },
  ];

  const rowStyle = {
    verticalAlign: "middle",
    textAlign: "center",
  };
  const handleTabSelect = async (key) => {
    if (key === "first") {
      setFirstTab(true);
    } else if (key === "second") {
      setSecondTab(true);
    } else if (key === "third") {
      setThirdTab(true)
    }
  };

  // if (!results.length && !loading) {
  //   return <h5 className="text-center mt-4">No data available</h5>;
  // }

  return (
    <>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} />

      {loading ? (
        <BlockingLoader />
      ) : (
        <>
          <div className="content">
            <BreadCrumb event={event} />

            {!event?.id && !loading ? (
              <h5 className="text-center mt-4">No data available</h5>
            ) : (
              <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">
                <div>
                  <Tabs defaultActiveKey="fifth" onSelect={handleTabSelect}>
                    <Tab eventKey="first" title="Upload Start List">
                      <UploadStartList
                        eventId={eventId}
                        firstTab={firstTab}
                        setFirstTab={setFirstTab}
                        slug={slug}
                        results={enhancedResults}
                      />
                    </Tab>
                    <Tab eventKey="second" title="Live Results">
                      <LiveResults
                        eventId={eventId}
                        secondTab={secondTab}
                        setSecondTab={setSecondTab}
                        slug={slug}
                        enhancedResults={enhancedResults}
                        updateResults={updateResults}
                      />
                    </Tab>
                    <Tab eventKey="third" title="Add Fields">
                        {/*<h3> This section is under development...</h3> */}
                      <AddFields slug={slug} eventId={eventId} />
                    </Tab>
                    <Tab eventKey="fourth" title="Upload Results">
                      <UploadResultsTab slug={slug} eventId={eventId} thirdTab={thirdTab} setThirdTab={setThirdTab} />
                    </Tab>
                    <Tab eventKey="fifth" title="Results">
                    <DisplayResultsTab slug={slug} eventId={eventId} />
                  </Tab>
                  </Tabs>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Results;
