import React, { useState, useEffect } from 'react'

import axios from "axios";
import { baseUrl } from "../apiConfig";

const IndividualCouponRunner = ({slug}) => {
  console.log(slug, "slug")
  const [event, setEvent] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}events/get-eventbyslug?slug=${slug}`);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [slug]);
  const [coupons, setCoupons] = useState([]);
  const fetchCoupons = async () => {
    try{
    const categoryNames = event?.category.map(category => category.name);
    console.log(categoryNames, "categoryNames")
    const filteredUsers = users.filter(user => categoryNames.includes(user.categoryName));
    console.log(filteredUsers, "filteredUsers");
    const uniqueCouponCodes = [...new Set(filteredUsers.map(user => user.couponCode))];
    console.log(uniqueCouponCodes, "uniqueCouponCodes")
    const couponDetailsPerCategory = [];
    filteredUsers.forEach(user => {
      const userCategory = user.categoryName;
      const userCouponCode = user.couponCode;
      if (uniqueCouponCodes.includes(userCouponCode)) {
        if (!couponDetailsPerCategory[userCategory]) {
          couponDetailsPerCategory[userCategory] = [];
        }
        couponDetailsPerCategory[userCategory].push({
          firstName: user.firstName,
          lastName: user.lastName,
          couponCode: user.couponCode,
        });
      }
    });

    console.log(couponDetailsPerCategory, "couponDetailsPerCategory");
    setCoupons(couponDetailsPerCategory);
  } catch (error) {
    console.error('Error fetching coupons:', error);
    // Handle error
  }

  };

  useEffect(() => {
    fetchCoupons();
  }, [event]);
  console.log(coupons, "coupons")
  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    const response = await axios.get(`${baseUrl}users/users?eventId=${event.id}`);
    setUsers(response.data.filter(user=>user.couponCode !== null));
  };

  useEffect(() => {
    if (event) {
      fetchUsers();
    }
  }, [event]);
  console.log(users, "users")



  useEffect(() => {
    fetchCoupons();
  }, [event]);


  return (
    <div className='p-3'>
      {users.length === 0 ? 
      <h3 className='text-center p-3 text-dark-1'>No data available</h3> 
      :
      <div className='table-responsive'>
        <table className="table table-striped text-center">
          <thead>
            <tr style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
              <th>Runner</th>
              <th>Category</th>
              <th>Coupon Used</th>
            </tr>
          </thead>
          <tbody>
            {users.map((item) => (
          <tr style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
                <td>{item.firstName} {" "} {item.lastName}</td>
                <td>{item.categoryName}</td>
                <td>{item.couponCode}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
}
    </div>
  );
}

export default IndividualCouponRunner;
