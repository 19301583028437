import React, { useState } from "react";
import Modal from "../../Common/Modal";
import BlockingLoader from "../../Common/Loader";

const UploadModal = (props) => {
  const {
    options,
    handleProceed,
    handleCancel,
    loading,
    error,
    setUploadError,
  } = props;

  const [data, setData] = useState({
    // eventType: "",
    eventCategory: "",
  });

  const handleDataChange = (event, type) => {
    // if (type === "category-type") {
    setUploadError("");
    setData({ ...data, eventCategory: event.target.value });
    // } else {
    //   setData({ ...data, eventType: event.target.value });
    // }
  };

  const renderOptions = () => {
    return options.map((option, index) => {
      return (
        <option key={index} value={option.toLowerCase()} className="capitalize">
          {option}
        </option>
      );
    });
  };

  return (
    <Modal modalStyles="modalStyles">
      {loading && <BlockingLoader />}
      <div className="upload-modal-container">
        {/* <div className="select-container">
          <label for="event-type" className="label">
            Select Event Type:{" "}
          </label>
          <select
            id="event-type"
            onChange={(event) => handleDataChange(event, "event-type")}
          >
            <option value="">Select an Event type</option>
            {renderOptions()}
          </select>
        </div> */}

        <div className="select-container">
          <label for="category-type" className="label">
            Select Category:{" "}
          </label>
          <select
            id="category-type"
            onChange={(event) => handleDataChange(event, "category-type")}
          >
            <option value="">Select a Category type</option>
            {renderOptions()}
          </select>
        </div>
        <div className="buttons-container">
          <button className="button" onClick={() => handleProceed(data)}>
            Proceed
          </button>
          <button className="button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </Modal>
  );
};

export default UploadModal;
