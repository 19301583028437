import { Link } from "react-router-dom";
import { useState } from "react";
import { isActiveLink } from "./LinkActiveChecker";
import { useLocation } from "react-router-dom";
import { sidebarContent } from "../utils/sidebar";

const Sidebar = ({ isOpen, handleToggle, slug }) => {
  const { pathname } = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };
  // const sidebarContent = [
  //   {
  //     id: 1,
  //     name: "Events",
  //     routePath: `/events`,
  //   },
  //   {
  //     id: 2,
  //     name: "Metrics",
  //     routePath: `/metrics`,
  //   },
  //   {
  //     id: 3,
  //     name: "Sheets",
  //     routePath: `/`,
  //     subSections: [
  //       {
  //         id: 1,
  //         name: "Sheets",
  //         routePath: "/sheets",
  //       },
  //       {
  //         id: 2,
  //         name: "Add Sheet",
  //         routePath: "/add-sheet",
  //       },
  //     ],
  //   },
  // ];

  return (
    <div className={`sidebar -dashboard ${isOpen ? "open" : ""}`}>
      {sidebarContent.map((item) => {
        const baseRoutePath = item.routePath.split("?")[0];

        return (
          <div className="sidebar__item" key={item.id}>
            <div
              className={`${
                isActiveLink(baseRoutePath, pathname) ? "-is-active" : ""
              } sidebar__button `}
            >
              {item.name === "Sheets" ? (
                <div style={{ cursor: "pointer" }} onClick={handleToggleMenu}>
                  <div className="d-flex align-items-center gap-2 text-15 lh-1 fw-500">
                    {item.name}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11.475 14.475L7.85 10.85q-.075-.075-.112-.162T7.7 10.5q0-.2.138-.35T8.2 10h7.6q.225 0 .363.15t.137.35q0 .05-.15.35l-3.625 3.625q-.125.125-.25.175T12 14.7t-.275-.05t-.25-.175"
                      ></path>
                    </svg>
                  </div>
                </div>
              ) : item.name === "Coupon Dashboard" ? (
                // If item is Coupon Dashboard, add slug as query param
                <Link
                  to={`${item.routePath}/${slug}`}
                  className="d-flex items-center text-15 lh-1 fw-500"
                  style={{ textDecoration: "none" }}
                >
                  {item.name}
                </Link>
              ) : (
                <Link
                  to={item.routePath}
                  className="d-flex items-center text-15 lh-1 fw-500"
                  style={{ textDecoration: "none" }}
                >
                  {item.name}
                </Link>
              )}
            </div>
            {item.subSections &&
              toggleMenu &&
              item.subSections.map((subItem) => {
                console.log(
                  subItem,
                  "sub item",
                  subItem.routePath.split("?")[0]
                );
                const subBaseRoutePath = subItem.routePath.split("?")[0];

                return (
                  <div
                    className="sidebar__item"
                    key={subItem.id}
                    style={{ marginLeft: "20px" }}
                  >
                    <div
                      className={`${
                        isActiveLink(subBaseRoutePath, pathname)
                          ? "-is-active"
                          : ""
                      } sidebar__button`}
                    >
                      <Link
                        to={subItem.routePath}
                        className="d-flex items-center text-15 lh-1 fw-500"
                        style={{ textDecoration: "none" }}
                      >
                        {subItem.name}
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
