import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import axios from 'axios';
import { baseUrl } from '../apiConfig';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ToastComponent from './Common/Toast';
import BlockingLoader from './Common/Loader';
const AddSheet = () => {
const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const handleToggle = () => {
      setIsOpen(!isOpen);
      console.log("clicked")
  };
  const [events, setEvents] = useState([])

  const fetchData = async()=> {
    const response = await axios.get(`${baseUrl}events/get-results`);
    setEvents(response.data); 
  }
  useEffect(()=>{
    fetchData();
      },[])

      const formik = useFormik({
        initialValues: {
       sheetId: "",
       sheetName:"",
       eventName: '',
      },

      validationSchema: Yup.object().shape({
        sheetId: Yup.string().required('Sheet Id is required'),
        sheetName: Yup.string().required('Sheet Name is required'),
        eventName: Yup.string().required('Event name is required'),
      }),

      onSubmit: async (values) => {
        try {
          setLoading(true);
          await axios.post(`${baseUrl}events/sheets`, values, {
            headers: {
              role: 'super admin',
            },
  });
          setToastMessage(`Google Sheet added successfully!`);

          setShowToast(true);
          setToastVariant('success');
          setTimeout(() => {
            navigate(`/sheets`);
          }, 1000);

        } catch (error) {
          console.error('Error adding sheet:', error);
          setShowToast(true);
         setToastMessage("Failed to add google sheet. Please try again.");
          setToastVariant('danger');
        }
        finally {
          setLoading(false);
        }
      }
      });


  const [selectedEvent, setSelectedEvent] = useState(null);
  const handleEventChange = (event) => {
    const selectedEventName = event.target.value;
    const selectedEvent = events.find(event => event.eventName === selectedEventName);
    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
      formik.setFieldValue("eventId", selectedEvent.id);
      formik.setFieldValue("eventName", selectedEvent.eventName); 
      formik.setFieldTouched("eventName", false)
    } else {
      setSelectedEvent(null);
      formik.setFieldValue("eventId", "");
      formik.setFieldValue("eventName", "");
    }
  };
  useEffect(()=>{
    if(showToast){
  window.scrollTo({top: 0, behavior:"smooth"})
    }
  }, [showToast])
    return (
        <>
            <div className='header-margin'></div>

<DashboardHeader handleToggle={handleToggle}/>
{loading && <BlockingLoader />}
            <Sidebar isOpen={isOpen} handleToggle={handleToggle} />

            <div className="content">
            <h1 className='text-center'>Add Sheet</h1>

            <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
          <form
        className="col-xl-7 col-lg-8 mt-30 mx-auto"
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
         formik.handleSubmit();
          return false;
        }}
      >
        <div className="row x-gap-40 y-gap-20">
        <div className="col-md-6 mt-4">
            <div className="form-input">
            <label className="text-16 text-light-1 text-dark-1 fw-bold">Sheet Id</label>
              <input
                type="text"
                className='form-control'
                id="sheetId"
                name="sheetId"
                 value={formik.values.sheetId}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                required
              />
              </div>
              {formik.touched.sheetId && formik.errors.sheetId ? (
                <div className="text-danger">{formik.errors.sheetId}</div>
              ) : null}
  </div>
  <div className="col-md-6 mt-4">
            <div className="form-input">
            <label className="text-16 text-dark-1 fw-bold">Sheet Name</label>
              <input
                type="text"
                className='form-control'
                id="sheetName"
                name="sheetName"
                 value={formik.values.sheetName}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                required
              />
              </div>
              {formik.touched.sheetName && formik.errors.sheetName ? (
                <div className="text-danger">{formik.errors.sheetName}</div>
              ) : null}
  </div>
        <div className="col-md-6 mt-4">
            <div className="form-input">
            <label className="text-16 text-dark-1 fw-bold">Event</label>
        <select
                id="eventName"
                className="p-2 form-select text-dark-1"
                name="eventName"
                onChange={handleEventChange}
                onBlur={formik.handleBlur}
                value={selectedEvent ? selectedEvent.eventName : ""}
              >
               <option value="">Select</option>
                {events?.map((event, index) => (
                  <option key={index} value={event.eventName}>
                    {event.eventName}
                  </option>
                ))}
              </select>
              {formik.touched.eventName && formik.errors.eventName && (
                <div className="text-danger">{formik.errors.eventName}</div>
              )}
</div>
</div>

          </div>
          <div className="row mt-4 text-center">
          <div className="col-12">
            <button
              className="btn btn-primary"
              type="submit"
            >
                Add Sheet
            </button>
          </div>
        </div>
          </form>
          </div>
        </div>
<ToastComponent
      showToast={showToast}
      toastHeader="Add Sheet"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
</div>
        </>
    )
}

export default AddSheet;