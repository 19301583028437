import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const SelectColumnsToDisplay = ({ show, columns, selectedColumns, setSelectedColumns, onContinue, onClose }) => {
    
    const customizedColumns = {
            firstName:'First Name',
            lastName:'Last Name',
            email:'Email',
            mobileNumber:'Mobile Number',
            gender:'Gender',
            dateOfBirth:'Date Of Birth',
            tShirtSize:'T-Shirt Size',
            nameOfTheBib:'Name On The Bib',
              categoryName:'Category',
              createdAt:'Time Of Registration',
              bloodGroup:'Blood Group',
                contactName:'Contact Name',
                contactNumber:'Contact Number',
                medicalIssue:'Medical Issue',
                runnerClub:'Runner Club',
                distance:'Distance',
                bibNumber:'Bib Number',
                chipCode:'Chip Code',
                runnerId: 'Runner Id',
                  isDistributed: 'Is Distributed',
                  distributedBy: 'Distributed By',
                    isIdVerified: 'Is Id Verified',
                    qrImageUrl: 'Qr Image Url',
                      isQRCodeSend: 'QR Code Send',
                        address: 'Address',
                        age: "Age",
                        isResultSMSSend: 'Is result SMS send',
                        disorders: 'Disorders',
                        chronicIssues: "Chronic Issues"
         
    }
    const defaultColumns = ["firstName", "lastName", "categoryName", "createdAt", "tShirtSize"];

    const handleSelectAll = () => {
        let newSelectedColumns = [];
        if (selectedColumns.length === columns.length) {
            newSelectedColumns = [];
        } else {
            newSelectedColumns = selectedColumns.filter(col => defaultColumns.includes(col));
            newSelectedColumns = [...newSelectedColumns, ...columns.filter(col => !newSelectedColumns.includes(col))];
        }
        setSelectedColumns(newSelectedColumns);
    };
    return (
        <Modal show={show} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Select Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <div className="d-flex flex-row gap-2">
                            <Form.Check
                                type="checkbox"
                                checked={selectedColumns.length === columns.length}
                                onChange={handleSelectAll}
                                label="Select All"
                            />
                        </div>
                    </Form.Group>
                    <div className='row justify-content-left'>
                        {columns.map(column => (
                            <div key={column} className="col-md-5">
                                <div className="d-flex flex-row gap-2">
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectedColumns.includes(column)}
                                        onChange={() => {
                                            if (selectedColumns.includes(column)) {
                                                setSelectedColumns(selectedColumns.filter(col => col !== column));
                                            } else {
                                                setSelectedColumns([...selectedColumns, column]);
                                            }
                                        }}
                                        label={customizedColumns[column] || column}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
                <Button variant="primary" onClick={onContinue}>Continue</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectColumnsToDisplay;
