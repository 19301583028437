import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ToastComponent from "../Common/Toast";
import BlockingLoader from "../Common/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";

import DashboardHeader from "../DashboardHeader";
import Sidebar from "../Sidebar";
import { baseUrl } from "../../apiConfig";

const BulkCoupon = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const slug = new URLSearchParams(location.search).get("slug");
  const { state } = location;
  const row = state && state?.row;
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const [events, setEvents] = useState([]);

  const fetchData = async () => {
    const response = await axios.get(`${baseUrl}events/get-results`);
    setEvents(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(events, "events");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventChange = (event) => {
    const selectedEventName = event.target.value;
    const selectedEvent = events.find(
      (event) => event.eventName === selectedEventName
    );
    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
      formik.setFieldValue("eventId", selectedEvent.id);
      formik.setFieldValue("eventName", selectedEvent.eventName);
      formik.setFieldTouched("eventName", false);
    } else {
      setSelectedEvent(null);
      formik.setFieldValue("eventId", "");
      formik.setFieldValue("eventName", "");
    }
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const formik = useFormik({
    initialValues: {
      eventName: "",
      length: null,
      prefix: "",
      count: null,
      eventId: null,
    },

    validationSchema: Yup.object().shape({
      prefix: Yup.string().required("Prefix is required"),
      length: Yup.number()
        .required("length is required")
        .integer("length must be an integer"),
      count: Yup.number()
        .required("Count is required")
        .integer("Count must be an integer"),
      eventId: Yup.number().required("Event ID is required"),
      eventName: Yup.string().required("Event name is required"),
    }),

    onSubmit: async (values) => {
      try {
        setLoading(true);
        await axios.post(`${baseUrl}coupons/bulk-create`, values);
        setToastMessage(`Bulk coupons created successfully!`);
        setShowToast(true);
        setToastVariant("success");
        setTimeout(() => {
          navigate(`/coupon-dashboard/${slug}`);
        }, 1000);
      } catch (error) {
        console.error("Error creating coupon:", error);
        setShowToast(true);
        setToastMessage("Failed to create bulk coupons. Please try again.");
        setToastVariant("danger");
      }
      finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (showToast) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showToast]);

  return (
    <>
      <style>
        {`
      .form-input {
        //position: relative;
      
        //display: flex;
      
        label {
          //position: absolute;
          top: 0;
          pointer-events: none;
          font-size: 14px;
        }
      
        textarea,
        input {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
        select {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
      
      input:not(:placeholder-shown) {
        padding-top: 0px;
      }
      textarea:not(:placeholder-shown) {
        padding-top: 5px;
      }
        textarea:focus ~ label,
        textarea:valid ~ label,
        input:focus ~ label,
        input:valid ~ label {
          transform: translateY(-10px);
        }
      }

      `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
{loading && <BlockingLoader />}
      <div className="content">
        {/* <BreadCrumb task={"Coupon"} /> */}

        <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
            <form
              className="col-xl-7 col-lg-8 mt-30 mx-auto"
              id="reg"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <div className="row x-gap-40 y-gap-20">
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Event <span className="text-danger">*</span>
                    </label>
                    <select
                      id="eventName"
                      className="p-2 form-select text-dark-1"
                      name="eventName"
                      onChange={handleEventChange}
                      onBlur={formik.handleBlur}
                      value={selectedEvent ? selectedEvent.eventName : ""}
                    >
                      <option value="">Select</option>
                      {events?.map((event, index) => (
                        <option key={index} value={event.eventName}>
                          {event.eventName}
                        </option>
                      ))}
                    </select>
                    {formik.touched.eventName && formik.errors.eventName && (
                      <div className="text-danger">
                        {formik.errors.eventName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Length <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="length"
                      name="length"
                      value={formik.values.length}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  {formik.touched.length && formik.errors.length ? (
                    <div className="text-danger">{formik.errors.length}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Prefix <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="prefix"
                      name="prefix"
                      value={formik.values.prefix}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  {formik.touched.prefix && formik.errors.prefix ? (
                    <div className="text-danger">{formik.errors.prefix}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Count <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="count"
                      name="count"
                      value={formik.values.count}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  {formik.touched.count && formik.errors.count ? (
                    <div className="text-danger">{formik.errors.count}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4 text-center">
                <div className="col-12">
                  <button className="btn btn-primary" type="submit">
                    Bulk Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastComponent
      showToast={showToast}
      toastHeader="Bulk Coupons"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  );
};

export default BulkCoupon;
