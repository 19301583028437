import { Link } from "react-router-dom";

const BreadCrumb = ({ event, slug, task }) => {
  return (
    <section className="custom-gray-bg  d-flex items-center">
      <div className="row y-gap-10 items-center justify-between">
        <div className="col-auto">
          <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
            <div className="col-auto fw-bold">
              <Link to="/events">Events</Link>
            </div>
            {event?.eventName && (
              <>
                <div className="col-auto fw-bold">/</div>
                <div className="col-auto fw-bold">
                  <Link to={`/event/${slug}`}>
                    {event?.eventName}
                  </Link>
                </div>
              </>
            )}
            {task && (
              <>
                <div className="col-auto fw-bold">/</div>
                <div className="col-auto fw-bold">{task}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
