import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import ToastComponent from './Common/Toast';

function GenerateBib({ slug, eventId, setLoadingGenerateBib }) {

    const [event, setEvent] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}events/categories?slug=${slug}`);
                setEvent(response.data);
                console.log(response.data, '----event Details');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [eventId]);

    const initialValues = {
        categoryName: '',
        bibStartingNum: null,
        numberOfExtraBib: null,
        chipCode: "",
        chipSerialNumber: ""
    };

    const validationSchema = Yup.object().shape({
        categoryName: Yup.string().required("Category Name is required"),
        bibStartingNum: Yup.number().required("Bib Starting Number is required"),
        numberOfExtraBib: Yup.number()
            .required('Number of bibs  is required'),
        chipCode: Yup.string().required("Chip code is required"),
        chipSerialNumber: Yup.string().required("Chip serial number is required")
    });

    const onSubmit = async (values, { setSubmitting,resetForm }) => {
        try {
            setLoadingGenerateBib(true);
            console.log(event.eventName,'-----EventName')
            values.eventName=event.eventName
            values.slug=event.slug
           const response =  await axios.post(`${baseUrl}events/bibGeneration?eventId=${eventId}`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (response?.status === 200) {
                console.log('status 200')
                setToastVariant("success");
                setToastMessage("Bibs Generated Successful!");
                resetForm();    
            }
            else{
                console.log('Api failed')
                const errorData = await response.json();
                console.error("Runner registration failed:", errorData);
                setToastVariant("danger");
                setToastMessage("Bibs Generation is failed");
            }
        } catch (error) {
            console.error("Error during signin:", error);
            setToastVariant("danger");
            setToastMessage("Bibs Generation is failed");
        }   finally {
            setLoadingGenerateBib(false);
            setShowToast(true);
            setSubmitting(false);
          }    

    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });
useEffect(()=>{
if(showToast) {
    window.scrollTo({ top: 0, behavior: "smooth" })
}
}, [showToast])
    return (
        <div className="container p-3 m-5 gap-2 justify-content-center align-items-center custom-min-height">
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col">
                        <div className="border p-5">
                            <p className="fw-bold text-center text-primary fs-3">Generate Bibs</p>
                            <div className="form-group form-input row m-3">
                                <label htmlFor="category" className="text-16 text-dark-1 fw-bold col-sm-5">Select Category Name</label>
                                <div className="col-sm-6">
                                    <select
                                        id="catgeories"
                                        className="form-select form-control-md text-dark-1"
                                        name="categoryName"
                                        onChange={(e) => formik.handleChange(e)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.categoryName || ""}
                                    >
                                        <option value="">Select Category Name</option>
                                        {event?.category?.map((category, index) => (
                                            <option key={index} value={category.name}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {formik.touched.categoryName && formik.errors.categoryName && (
                                    <div className="text-danger m-1">{formik.errors.categoryName}</div>
                                )}
                            </div>
                            <div className="form-group form-input row m-3">
                                <label htmlFor="bibStartingNum" className="text-16 text-dark-1 fw-bold col-sm-5">Bib Starting Number</label>
                                <div className="col-sm-6">
                                    <input
                                        type="number"
                                        className="form-control form-control-md custom-placeholder"
                                        id="bibStartingNum"
                                        name="bibStartingNum"
                                        placeholder='Enter Bib Staring Number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.bibStartingNum || ""}
                                        required
                                    />
                                </div>
                                {formik.touched.bibStartingNum && formik.errors.bibStartingNum && (
                                    <div className="text-danger m-1">{formik.errors.bibStartingNum}</div>
                                )}
                            </div>
                            <div className="form-group form-input row m-3">
                                <label htmlFor="NumberOfExtraBib" className="text-16 text-dark-1 fw-bold col-sm-5">Number of extra Bibs</label>
                                <div className="col-sm-6">
                                    <input
                                        type="number"
                                        className="form-control form-control-md custom-placeholder"
                                        id="numberOfExtraBib"
                                        name="numberOfExtraBib"
                                        placeholder='Enter Number of extra Bibs'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.numberOfExtraBib || ""}
                                        required
                                    />
                                </div>
                                {formik.touched.numberOfExtraBib && formik.errors.numberOfExtraBib && (
                                    <div className="text-danger m-1">{formik.errors.numberOfExtraBib}</div>
                                )}
                            </div>
                            <div className="form-group form-input row m-3">
                                <label htmlFor="chipCode" className="text-16 text-dark-1 fw-bold col-sm-5">Enter chip code serial number</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-md custom-placeholder"
                                        id="chipCode"
                                        name="chipCode"
                                        placeholder='Enter chip code'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.chipCode || ""}
                                        required
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-md custom-placeholder"
                                        id="chipSerialNumber"
                                        name="chipSerialNumber"
                                        placeholder='Enter chip serial number'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.chipSerialNumber || ""}
                                        required
                                    />
                                </div>
                                {formik.touched.chipCode && formik.errors.chipCode && (
                                    <div className="text-danger m-1">{formik.errors.chipCode}</div>
                                )}
                                {formik.touched.chipSerialNumber && formik.errors.chipSerialNumber && (
                                    <div className="text-danger m-1">{formik.errors.chipSerialNumber}</div>
                                )}
                            </div>
                            <div className="row x-gap-20 y-gap-20 pt-20">
                                <div className='col-12 text-center'>
                                    <button type="submit" className='btn btn-primary border px-4 py-2'>Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
          <ToastComponent
      showToast={showToast}
      toastHeader="Bib Generation"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
        </div>
    )
}

export default GenerateBib
